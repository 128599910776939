import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const MenuCatalog = () => {
  const { t } = useTranslation();
  const brand = useSelector((state) => state.brand.active);

  return (
    <div className="w-100 account-menu-sub-bg">
      <nav className="account-menu-sub">
        <ul>
          <li>
            <NavLink to="categories" end>
              {t("Категории")}
            </NavLink>
          </li>
          <li>
            <NavLink to="products">{t("Товары")}</NavLink>
          </li>
          <li>
            <NavLink to="additions">{t("Добавки")}</NavLink>
          </li>
          <li>
            <NavLink to="modifiers">{t("Модификаторы")}</NavLink>
          </li>
          {(brand?.type === "shop" || brand?.type == "service") && (
            <li>
              <NavLink to="params">{t("Параметры")}</NavLink>
            </li>
          )}
          <li>
            <NavLink to="wishes">{t("Пожелания")}</NavLink>
          </li>
          <li>
            <NavLink to="storages" disabled>
              {t("Склад")}
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MenuCatalog;
