import React, { useCallback, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import { createBanner } from "../../services/banner";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Select from "../../components/UI/Select";
import { getCategories } from "../../services/category";
import { getProductList } from "../../services/product";
import { getSales } from "../../services/sale";

const BannerCreate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const affiliate = useSelector((state) => state.affiliate.data);
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [sales, setSales] = useState([]);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  useEffect(() => {
    if (data?.options?.linkType === "category" && categories?.length === 0) {
      getCategories({ size: 200 })
        .then((res) => {
          if (res?.items?.length > 0) {
            setCategories(
              res.items.map((e) => ({ title: e.title, value: e.id }))
            );
          }
        })
        .catch(() => setCategories([]));
    } else if (
      data?.options?.linkType === "product" &&
      products?.length === 0
    ) {
      getProductList({ size: 500 })
        .then((res) => {
          if (res?.length > 0) {
            setProducts(res.map((e) => ({ title: e.title, value: e.id })));
          }
        })
        .catch(() => setProducts([]));
    } else if (data?.options?.linkType === "sale" && sales?.length === 0) {
      getSales({ size: 200 })
        .then((res) => {
          if (res?.items?.length > 0) {
            setSales(res.items.map((e) => ({ title: e.title, value: e.id })));
          }
        })
        .catch(() => setSales([]));
    }
  }, [data?.options?.linkType]);

  const onSubmit = useCallback((data) => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.file) {
      for (let file of data?.file) {
        formData.append("file", file);
      }
    }

    createBanner(formData)
      .then(() => {
        NotificationManager.success("Баннер успешно создан");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  return (
    <>
      <Meta title="Создать баннер" />
      <div>
        <Link
          to="/content/slides"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>Создать баннер</h3>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить
        </Button>
      </div>
      <Card>
        <Card.Body>
          <Tabs className="mb-3" fill>
            <Tab.Pane eventKey={0} title={t("Основное")}>
              <Row>
                <Col md={6}>
                  <DragDropFile
                    maxAspect={16 / 7}
                    file={data.file}
                    onChange={(e) => setValue("file", e)}
                    onDelete={(e) => setValue("file", e.file)}
                  />
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      defaultValue={data.priority ?? 0}
                      label="Порядок"
                      name="priority"
                      errors={errors}
                      register={register}
                    />
                  </div>
                  <h5 className="mb-4">Настройки даты и времени показа</h5>
                  <Row>
                    <Col md={6}>
                      <Input
                        label="Начало"
                        type="datetime-local"
                        name="options.start"
                        register={register}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        label="Конец"
                        type="datetime-local"
                        name="options.end"
                        register={register}
                      />
                    </Col>
                    <Col md={6}>
                      <Form.Check className="mt-3">
                        <Form.Check.Input
                          type="checkbox"
                          name="status"
                          id="status"
                          defaultChecked={data.status}
                          {...register("status")}
                        />
                        <Form.Check.Label htmlFor="status" className="ms-2">
                          Показать\Скрыть баннер
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                  </Row>
                  <h5 className="mb-4 mt-3">Настройки ссылки</h5>
                  <Row>
                    <Col md={6}>
                      <Select
                        classNameContainer="w-100"
                        label="Тип ссылки"
                        data={[
                          { title: "Нет", value: null },
                          { title: "Все категории", value: "categories" },
                          { title: "Категория", value: "category" },
                          { title: "Все акции", value: "sales" },
                          { title: "Акция", value: "sale" },
                          { title: "Товар", value: "product" },
                        ]}
                        value={data.options?.linkType ?? null}
                        onClick={(e) => {
                          setValue("options.linkType", e.value);
                        }}
                      />
                    </Col>
                    {data.options?.linkType === "category" &&
                      categories?.length > 0 && (
                        <Col md={6}>
                          <Select
                            search
                            classNameContainer="w-100"
                            label="Категория"
                            data={categories}
                            value={data.options?.linkValue ?? null}
                            onClick={(e) => {
                              setValue("options.linkValue", e.value);
                            }}
                          />
                        </Col>
                      )}
                    {data.options?.linkType === "product" &&
                      products?.length > 0 && (
                        <Col md={6}>
                          <Select
                            search
                            classNameContainer="w-100"
                            label="Товар"
                            data={products}
                            value={data.options?.linkValue ?? null}
                            onClick={(e) => {
                              setValue("options.linkValue", e.value);
                            }}
                          />
                        </Col>
                      )}
                    {data.options?.linkType === "sale" && sales?.length > 0 && (
                      <Col md={6}>
                        <Select
                          search
                          classNameContainer="w-100"
                          label="Акция"
                          data={sales}
                          value={data.options?.linkValue ?? null}
                          onClick={(e) => {
                            setValue("options.linkValue", e.value);
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey={1} title={t("Филиалы")}>
              <p className="mb-3 text-muted fs-09">
                Если ни один филиал не выбран, баннер будет показан во всех
                филиалах.
              </p>
              {affiliate?.length > 0 && (
                <>
                  <Form.Check className="mb-3">
                    <Form.Check.Input
                      type="checkbox"
                      name="options.affiliateView"
                      checked={data?.options?.affiliateView}
                      id="affiliate_view"
                      onChange={() => {
                        setValue(
                          "options.affiliateView",
                          !!!data?.options?.affiliateView
                        );
                        if (data?.options?.affiliateView) {
                          setValue("relationBanners", []);
                        }
                      }}
                    />
                    <Form.Check.Label
                      htmlFor="affiliate_view"
                      className="ms-2 fs-09"
                    >
                      Вкл\Выкл показ по филиалам
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="mb-3">
                    <Form.Check.Input
                      type="checkbox"
                      disabled={!data?.options?.affiliateView}
                      checked={
                        affiliate?.length > 0 &&
                        affiliate?.length === data?.relationBanners?.length
                      }
                      id="affiliate_all"
                      onChange={() => {
                        if (
                          affiliate?.length > 0 &&
                          affiliate?.length === data?.relationBanners?.length
                        ) {
                          setValue("relationBanners", []);
                        } else {
                          setValue(
                            "relationBanners",
                            affiliate.map((e) => ({
                              affiliateId: e.id,
                            }))
                          );
                        }
                      }}
                    />
                    <Form.Check.Label
                      htmlFor="affiliate_all"
                      className="ms-2 fs-09"
                    >
                      Выделить все
                    </Form.Check.Label>
                  </Form.Check>
                  <hr />
                </>
              )}
              {affiliate?.length > 0 &&
                affiliate.map((e) => {
                  let option =
                    data?.relationBanners?.length > 0 &&
                    data.relationBanners.find(
                      (item) => item.affiliateId === e.id
                    );

                  return (
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Form.Check>
                        <Form.Check.Input
                          type="checkbox"
                          name="relationBanners"
                          id={"affiliate_" + e.id}
                          disabled={!data?.options?.affiliateView}
                          checked={!!option}
                          onChange={() => {
                            if (!!option) {
                              let newArray = data.relationBanners.filter(
                                (item) => item.affiliateId != e.id
                              );
                              setValue("relationBanners", newArray);
                            } else {
                              let newArray =
                                data?.relationBanners?.length > 0
                                  ? [
                                      ...data.relationBanners,
                                      {
                                        affiliateId: e.id,
                                      },
                                    ]
                                  : [
                                      {
                                        affiliateId: e.id,
                                      },
                                    ];
                              setValue("relationBanners", newArray);
                            }
                          }}
                        />
                        <Form.Check.Label
                          htmlFor={"affiliate_" + e.id}
                          className="ms-2 fs-09"
                        >
                          {e?.title ? e.title : e.full}
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                  );
                })}
            </Tab.Pane>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

export default BannerCreate;
