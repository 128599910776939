import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline, IoTrashOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import useDebounce from "../../hooks/useDebounce";
import { editCity, getCity } from "../../services/city";
import { getDadataCity } from "../../services/dadata";

const CityEdit = () => {
  const { cityId } = useParams();
  const navigate = useNavigate();
  const [cities, setCities] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [loading, setLoading] = useState(true);

  const [settlements, setSettlements] = useState([]);
  const [showDropdownSettlement, setShowDropdownSettlement] = useState(false);
  const [settlement, setSettlement] = useState(false);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      status: 1,
      title: null,
      country: null,
      options: {
        view: "",
      },
    },
  });
  const data = useWatch({ control });

  const cityText = useDebounce(data.title, 1000);
  const settlementText = useDebounce(settlement, 1000);

  const clickCity = (city) => {
    setValue("title", city.data.city ?? city.data.settlement ?? null);
    setValue("country", city.data.country ?? null);
    setValue("region", city.data.region ?? null);
    setShowDropdown(false);
  };

  const onKeyDown = (e) => {
    if (e === "Enter" && cities?.length > 0) {
      clickCity(cities[0]);
      setCities([]);
    }
  };

  const clickSettlement = (city) => {
    setValue(
      "options.settlements",
      data?.options?.settlements?.length > 0
        ? [
            ...data.options.settlements,
            { title: city.data?.settlement ?? city.data?.city ?? null },
          ]
        : [{ title: city.data?.settlement ?? city.data?.city ?? null }]
    );
    setShowDropdownSettlement(false);
  };

  const onKeyDownSettlement = (e) => {
    if (e === "Enter" && settlements?.length > 0) {
      clickSettlement(settlements[0]);
      setSettlements([]);
    }
  };

  useEffect(() => {
    if (cityText) {
      getDadataCity(cityText).then((res) => {
        if (res?.data?.suggestions) {
          setCities(res.data.suggestions);
        }
      });
    }
  }, [cityText]);

  useEffect(() => {
    if (settlementText) {
      getDadataCity(settlementText).then((res) => {
        if (res?.data?.suggestions) {
          setSettlements(res.data.suggestions);
        }
      });
    }
  }, [settlementText]);

  const onSubmit = useCallback((data) => {
    editCity(data)
      .then((res) => {
        NotificationManager.success("Город успешно обновлен");
        navigate(-1);
      })
      .catch((err) => {
        NotificationManager.error(
          err.response.data.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  useLayoutEffect(() => {
    getCity(cityId)
      .then(
        (res) =>
          res &&
          reset((prev) => ({
            ...prev,
            ...res,
          }))
      )
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Редактировать город" />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/options/affiliates/cities"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button onClick={handleSubmit(onSubmit)}>Сохранить изменения</Button>
      </div>
      <h3 className="mb-4">Редактировать город</h3>
      <Row>
        <Col md={6}>
          <div className="position-relative">
            <Input
              errors={errors}
              label="Город"
              onKeyDown={(e) => onKeyDown(e)}
              onClick={() => setShowDropdown(true)}
              type="search"
              autoComplete="off"
              name="title"
              placeholder="Начните писать город..."
              register={register}
              validation={{
                required: "Обязательное поле",
                maxLength: { value: 50, message: "Максимум 50 символов" },
              }}
            />
            {showDropdown && cities?.length > 0 && (
              <Dropdown.Menu
                onClick={() => setShowDropdown(false)}
                show
                className="w-100 custom-input-city"
              >
                {cities.map(
                  (item, key) =>
                    item && (
                      <Dropdown.Item onClick={() => clickCity(item)} key={key}>
                        {item.value}
                      </Dropdown.Item>
                    )
                )}
              </Dropdown.Menu>
            )}
          </div>
          <div className="mt-4">
            <Input
              errors={errors}
              label="Произвольное название"
              autoComplete="off"
              name="options.alias"
              placeholder="Введите название"
              register={register}
              validation={{
                maxLength: { value: 50, message: "Максимум 50 символов" },
              }}
            />
            <p className="text-muted mt-1 fs-08">
              Вы можете задать свое название городу
            </p>
          </div>
          <div className="position-relative mt-4">
            <Input
              label="Населенный пункт"
              placeholder="Начните писать название..."
              errors={errors}
              type="search"
              autoComplete="off"
              name="settlement"
              onKeyDown={(e) => onKeyDownSettlement(e)}
              onClick={() => setShowDropdownSettlement(true)}
              onChange={(e) => setSettlement(e)}
            />
            {showDropdownSettlement && settlements?.length > 0 && (
              <Dropdown.Menu
                onClick={() => setShowDropdownSettlement(false)}
                show
                className="w-100 custom-input-city"
              >
                {settlements.map(
                  (item, key) =>
                    item && (
                      <Dropdown.Item
                        onClick={() => clickSettlement(item)}
                        key={key}
                      >
                        {item.value}
                      </Dropdown.Item>
                    )
                )}
              </Dropdown.Menu>
            )}
          </div>
          {data?.options?.settlements?.length > 0 &&
            data.options.settlements.map((item, index) => {
              return (
                <div className="mt-2 d-flex flex-row align-items-center">
                  <Input
                    className="w-100"
                    defaultValue={item?.title ?? null}
                    name={`options.settlements.${index}.title`}
                    register={register}
                  />
                  <Button
                    className="btn-light ms-2"
                    onClick={() =>
                      setValue(
                        "options.settlements",
                        data.options.settlements.filter(
                          (e) => e.title != item.title
                        )
                      )
                    }
                  >
                    <IoTrashOutline size={20} />
                  </Button>
                </div>
              );
            })}
          <Form.Check className="mt-4">
            <Form.Check.Input
              type="checkbox"
              name="status"
              id="status"
              {...register("status")}
            />
            <Form.Check.Label htmlFor="status" className="ms-2">
              Работает\Не работает
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col>
          <h5 className="mb-3">Показать в меню</h5>
          <Form.Check className="mb-2">
            <Form.Check.Input
              type="radio"
              name="options.view"
              value=""
              id="viewCity"
              {...register("options.view")}
            />
            <Form.Check.Label htmlFor="viewCity" className="ms-2">
              Город
            </Form.Check.Label>
          </Form.Check>
          <Form.Check className="mb-2">
            <Form.Check.Input
              type="radio"
              name="options.view"
              value="region"
              id="viewRegion"
              {...register("options.view")}
            />
            <Form.Check.Label htmlFor="viewRegion" className="ms-2">
              Регион
            </Form.Check.Label>
          </Form.Check>
          <Form.Check className="mb-2">
            <Form.Check.Input
              type="radio"
              name="options.view"
              value="country"
              id="viewCountry"
              {...register("options.view")}
            />
            <Form.Check.Label htmlFor="viewCountry" className="ms-2">
              Страна
            </Form.Check.Label>
          </Form.Check>
          <Form.Check className="mb-2">
            <Form.Check.Input
              type="radio"
              name="options.view"
              value="no"
              id="viewNo"
              {...register("options.view")}
            />
            <Form.Check.Label htmlFor="viewNo" className="ms-2">
              Не показывать
            </Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>
    </>
  );
};

export default CityEdit;
