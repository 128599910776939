import React, { useCallback, useState } from "react";
import { Badge, Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { BlockPicker } from "react-color";
import { useForm, useWatch } from "react-hook-form";
import { IoAdd, IoCreateOutline, IoTrashOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import DragDropFile from "../../../components/DragDropFile";
import Button from "../../../components/UI/Button";
import Input from "../../../components/UI/Input";
import Select from "../../../components/UI/Select";
import Textarea from "../../../components/UI/Textarea";
import CustomModal from "../../../components/utils/CustomModal";
import { editModuleApp } from "../../../services/module";
import { HexAlphaColorPicker, HexColorPicker } from "react-colorful";
import { localeData } from "../../../helpers/all";

const syncAuthData = [
  {
    title: "По почте",
    value: "email",
  },
  {
    title: "По номеру телефона",
    value: "phone",
  },
];

const syncThemeTypeData = [
  {
    title: "Системная (По умолчанию)",
    value: false,
  },
  {
    title: "Темная",
    value: "dark",
  },
  {
    title: "Светлая",
    value: "light",
  },
];

const syncPointData = [
  {
    title: "Номер телефона",
    value: "phone",
  },
  {
    title: "Email",
    value: "email",
  },
  {
    title: "Идентификатор из интеграции",
    value: "apiId",
  },
];

const syncThemeProduct = [
  {
    title: "Средний размер изображения",
    value: 0,
  },
  {
    title: "Большой размер изображения",
    value: 1,
  },
];

const syncProductColumn = [
  {
    title: "Один в ряд",
    value: 0,
  },
  {
    title: "Два в ряд",
    value: 1,
  },
];

const syncThemeAddition = [
  {
    title: "Список по вертикали",
    value: 0,
  },
  {
    title: "Список по горизонтали",
    value: 1,
  },
];

const pageData = [
  {
    title: "Главная",
    value: "/",
  },
  {
    title: "Каталог",
    value: "/categories",
  },
  {
    title: "Акции",
    value: "/promo",
  },
  {
    title: "Услуги",
    value: "/services",
  },
  {
    title: "Проекты",
    value: "/projects",
  },
  {
    title: "О нас",
    value: "/about",
  },
  {
    title: "Контакты",
    value: "/contact",
  },
  {
    title: "Портфолио",
    value: "/portfolio",
  },
  {
    title: "Новости",
    value: "/blogs",
  },
  {
    title: "Произвольная ссылка",
    value: false,
  },
];

const menuData = [
  {
    id: 1,
    edit: false,
    title: "Главная",
    value: false,
  },
  {
    id: 2,
    edit: true,
    value: "notifications",
    title: null,
  },
  {
    id: 3,
    edit: false,
    value: false,
    title: "Акции",
  },
  {
    id: 4,
    edit: false,
    value: false,
    title: "Корзина",
  },
  {
    id: 5,
    edit: false,
    value: false,
    title: "Профиль/Вход",
  },
];

const AppForm = ({ data }) => {
  const brand = useSelector((state) => state?.brand?.active);

  const defaultMenu = {
    show: false,
    data: {
      title: null,
      page: "/",
      type: "light",
      link: null,
      mobile: false,
      icon: false,
      order: 0,
      status: true,
    },
  };
  const [showMenu, setShowMenu] = useState(defaultMenu);
  const [showMenuEdit, setShowMenuEdit] = useState(defaultMenu);

  const defaultWidget = {
    show: false,
    data: { title: null, value: null, desc: null, order: 0, status: true },
  };
  const [showWidget, setShowWidget] = useState(defaultWidget);
  const [showWidgetEdit, setShowWidgetEdit] = useState(defaultWidget);

  const widgetData =
    brand?.type == "food"
      ? [
          {
            title: "Привет блок",
            value: "hello",
          },
          {
            title: "Истории",
            value: "stories",
          },
          {
            title: "Баннеры",
            value: "banners",
          },
          {
            title: "Акции",
            value: "sales",
          },
          {
            title: "Меню",
            value: "menu",
          },
          {
            title: "Категории",
            value: "categories",
          },
          {
            title: "Квиз",
            value: "quiz",
          },
          {
            title: "Портфолио",
            value: "portfolio",
          },
          {
            title: "Обратная связь",
            value: "contact",
          },
          {
            title: "Новости",
            value: "blogs",
          },
          {
            title: "О нас",
            value: "about",
          },
          {
            title: "Популярное",
            value: "popular",
          },
        ]
      : [
          {
            title: "Привет блок",
            value: "hello",
          },
          {
            title: "Истории",
            value: "stories",
          },
          {
            title: "Баннеры",
            value: "banners",
          },
          {
            title: "Акции",
            value: "sales",
          },
          {
            title: "Категории",
            value: "categories",
          },
          {
            title: "Товары",
            value: "products",
          },
          {
            title: "Проекты",
            value: "projects",
          },
          {
            title: "Услуги",
            value: "services",
          },
          {
            title: "Квиз",
            value: "quiz",
          },
          {
            title: "Портфолио",
            value: "portfolio",
          },
          {
            title: "Обратная связь",
            value: "contact",
          },
          {
            title: "Новости",
            value: "blogs",
          },
          {
            title: "О нас",
            value: "about",
          },
          {
            title: "Популярное",
            value: "popular",
          },
        ];

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues:
      data && !Array.isArray(data)
        ? data
        : {
            cart: true,
            menu: {
              0: {
                id: 1,
                edit: false,
                title: "Главная",
                value: false,
              },
              1: {
                id: 2,
                edit: true,
                title: "Уведомления",
                value: "notifications",
              },
              2: {
                id: 3,
                edit: false,
                title: "Акции",
                value: false,
              },
              3: {
                id: 4,
                edit: false,
                title: "Корзина",
                value: false,
              },
              4: {
                id: 5,
                edit: false,
                title: "Профиль/Вход",
                value: false,
              },
            },
            name: "",
            title: "",

            qrType: "phone",
            scheme: "",
            authType: "phone",
            colorBtn: "#ff5252",
            delivery: {
              status: true,
              card: true,
              cash: true,
              online: false,
            },
            pickup: {
              status: true,
              card: true,
              cash: true,
              online: false,
            },
            hall: {
              status: true,
              card: true,
              cash: true,
              online: false,
            },
            colorMain: "#ff5252",
            multiBrand: null,
            versionIos: "0.0.1",
            giftVisible: null,
            promoVisible: null,
            themeProduct: 0,
            themeAddition: 0,
            supportVisible: true,
            versionAndroid: "0.0.1",
            themeProductColumn: 1,
            checkoutSuccessText: null,
            profilePointVisible: true,
            productEnergyVisible: null,
          },
  });

  const form = useWatch({ control });

  const onCreateMenu = () => {
    if (!showMenu?.data?.title) {
      NotificationManager.error("Нет текста пункта меню");
    }
    if (!showMenu?.data?.page && !showMenu?.data?.link) {
      NotificationManager.error("Нет раздела или ссылки пункта меню");
    }
    if (!showMenu?.data?.type) {
      NotificationManager.error("Нет стиля пункта меню");
    }
    let array =
      form?.relationModule?.options?.menu?.length > 0 &&
      Array.isArray(form.relationModule.options.menu)
        ? form.relationModule.options.menu
        : [];
    array.push(showMenu.data);
    setValue("relationModule.options.menu", array);
    setShowMenu(defaultMenu);
  };

  const onEditMenu = () => {
    if (!showMenuEdit?.data?.title) {
      return NotificationManager.error("Нет текста пункта меню");
    }
    if (!showMenuEdit?.data?.page && !showMenuEdit?.data?.link) {
      return NotificationManager.error("Нет раздела или ссылки пункта меню");
    }
    if (!showMenuEdit?.data?.type) {
      return NotificationManager.error("Нет стиля пункта меню");
    }
    let array =
      form?.relationModule?.options?.menu?.length > 0 &&
      Array.isArray(form.relationModule.options.menu)
        ? form.relationModule.options.menu
        : [];

    if (array.length > 0) {
      array[showMenuEdit.index] = showMenuEdit.data;
    }

    setValue("relationModule.options.menu", array);
    setShowMenuEdit(defaultMenu);
  };

  const onEditWidget = () => {
    if (!showWidgetEdit?.data?.value) {
      return NotificationManager.error("Нет типа виджета");
    }
    let array =
      form?.relationModule?.options?.widget?.length > 0 &&
      Array.isArray(form.relationModule.options.widget)
        ? form.relationModule.options.widget
        : [];

    if (array.length > 0) {
      array[showWidgetEdit.index] = showWidgetEdit.data;
    }

    setValue("relationModule.options.widget", array);
    setShowWidgetEdit(defaultWidget);
  };

  const onDeleteMenu = (index) => {
    if (
      form?.relationModule?.options?.menu?.length > 0 &&
      Array.isArray(form.relationModule.options.menu)
    ) {
      setValue(
        "relationModule.options.menu",
        form.relationModule.options.menu.filter((e, i) => i !== index)
      );
    }
  };

  const onCreateWidget = () => {
    if (!showWidget?.data?.value) {
      NotificationManager.error("Вы не выбрали виджет");
    }
    let array =
      form?.relationModule?.options?.widget?.length > 0 &&
      Array.isArray(form.relationModule.options.widget)
        ? form.relationModule.options.widget
        : [];
    array.push(showWidget.data);
    setValue("relationModule.options.widget", array);
    setShowWidget(defaultWidget);
  };

  const onDeleteWidget = (index) => {
    if (
      form?.relationModule?.options?.widget?.length > 0 &&
      Array.isArray(form.relationModule.options.widget)
    ) {
      setValue(
        "relationModule.options.widget",
        form.relationModule.options.widget.filter((e, i) => i !== index)
      );
    }
  };

  const onSubmit = useCallback((data) => {
    if (
      !data?.relationModule?.options?.pickup?.status &&
      !data?.relationModule?.options?.delivery?.status &&
      !data?.relationModule?.options?.hall?.status &&
      !data?.relationModule?.options?.feedback?.status
    ) {
      return NotificationManager.error(
        "Тип доставки должен быть выбран хотя бы один"
      );
    }
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.feedback) {
      for (let file of data.feedback) {
        formData.append("feedback", file);
      }
    }
    if (data?.widgetAbout) {
      for (let file of data.widgetAbout) {
        formData.append("widgetAbout", file);
      }
    }
    if (data?.widgetHello) {
      for (let file of data.widgetHello) {
        formData.append("widgetHello", file);
      }
    }
    editModuleApp(formData)
      .then(() => {
        NotificationManager.success("Приложение успешно обновлено");
      })
      .catch(
        (err) =>
          err && NotificationManager.error("Ошибка при сохранении данных")
      );
  }, []);

  return (
    <Card body className="mb-4">
      <h3 className="mb-4 fw-7">Настройки</h3>
      <Tabs defaultActiveKey={0} className="mb-3" fill>
        <Tab eventKey={0} title="Основное">
          <Row>
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <div className="mb-4">
                    <Input
                      label="Название"
                      name="relationModule.options.title"
                      placeholder="Например: YooApp"
                      errors={errors}
                      register={register}
                      validation={{ required: "Обязательное поле" }}
                    />
                    <small className="text-muted fs-07">
                      Название вашего приложения
                    </small>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-4">
                    <Input
                      label="Scheme"
                      name="relationModule.options.scheme"
                      placeholder="Например: yooapp"
                      errors={errors}
                      register={register}
                    />
                    <small className="text-muted fs-07">
                      Scheme должно быть уникально и в нижнем регистре
                    </small>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-4">
                    <Input
                      label="Название Android"
                      name="relationModule.options.nameAndroid"
                      placeholder="Например: ru.yooapp.app"
                      errors={errors}
                      register={register}
                    />
                  </div>
                  <div className="mb-4">
                    <Input
                      label="Версия Android"
                      name="relationModule.options.versionAndroid"
                      placeholder="Например: 0.0.1"
                      errors={errors}
                      register={register}
                    />
                    <small className="text-muted fs-07">
                      Версия и название должны совпадать с Google Play Console
                    </small>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-4">
                    <Input
                      label="Название iOS"
                      name="relationModule.options.titleIos"
                      placeholder="Например: YooApp"
                      errors={errors}
                      register={register}
                    />
                  </div>
                  <div className="mb-4">
                    <Input
                      label="ID пакета iOS"
                      name="relationModule.options.nameIos"
                      placeholder="Например: ru.yooapp.app"
                      errors={errors}
                      register={register}
                    />
                  </div>
                  <div className="mb-4">
                    <Input
                      label="Версия iOS"
                      name="relationModule.options.versionIos"
                      placeholder="Например: 0.0.1"
                      errors={errors}
                      register={register}
                    />
                    <small className="text-muted fs-07">
                      Версия и название должны совпадать с Apple Developer
                    </small>
                  </div>
                  <div className="mb-4">
                    <Input
                      label="Apple ID"
                      name="relationModule.options.accountApple"
                      placeholder="Например: 123456789"
                      errors={errors}
                      register={register}
                    />
                    <small className="text-muted fs-07">
                      Идентификатор приложения Apple Developer
                    </small>
                  </div>
                </Col>
                <Col>
                  <Form.Check className="mb-4 d-inline-block me-3">
                    <Form.Check.Input
                      type="checkbox"
                      name="relationModule.options.multiBrand"
                      id="multiBrand"
                      {...register("relationModule.options.multiBrand")}
                    />
                    <Form.Check.Label htmlFor="multiBrand" className="ms-2">
                      Мультибренд
                    </Form.Check.Label>
                    <small className="ms-2 text-muted fs-07">
                      Вкл\Откл выбор разных заведений
                    </small>
                  </Form.Check>
                </Col>
              </Row>
            </Col>
          </Row>
          <h5 className="mb-4 fw-7">Профиль</h5>
          <div className="mb-4">
            <Select
              label="Тип аутентификации"
              onClick={(e) =>
                setValue("relationModule.options.authType", e.value)
              }
              value={form?.relationModule?.options?.authType ?? "phone"}
              data={syncAuthData}
            />
          </div>
          <Form.Check className="mb-4 d-inline-block me-3">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.profilePointVisible"
              id="profilePointVisible"
              defaultChecked={
                form?.relationModule?.options?.profilePointVisible
              }
              {...register("relationModule.options.profilePointVisible")}
            />
            <Form.Check.Label htmlFor="profilePointVisible" className="ms-2">
              Показывать бонусы в профиле
            </Form.Check.Label>
          </Form.Check>
          <div className="mb-4">
            <Select
              label="Идентификатор QR"
              onClick={(e) =>
                setValue("relationModule.options.qrType", e.value)
              }
              value={form?.relationModule?.options?.qrType ?? "phone"}
              data={syncPointData}
            />
          </div>
          <div>
            <Form.Check className="mb-4 d-inline-block me-3">
              <Form.Check.Input
                type="checkbox"
                name="relationModule.options.supportVisible"
                id="supportVisible"
                defaultChecked={form?.relationModule?.options?.supportVisible}
                {...register("relationModule.options.supportVisible")}
              />
              <Form.Check.Label htmlFor="supportVisible" className="ms-2">
                Вкл\Выкл тех поддержку
              </Form.Check.Label>
            </Form.Check>
          </div>
          <Form.Check className="mb-4 d-inline-block me-3">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.qr"
              id="qr"
              defaultChecked={form?.relationModule?.options?.qr}
              {...register("relationModule.options.qr")}
            />
            <Form.Check.Label htmlFor="qr" className="ms-2">
              Вход по QR коду
            </Form.Check.Label>
          </Form.Check>
          <hr />
          <h5 className="mb-4 fw-7">Товар</h5>
          <Form.Check className="mb-4 d-inline-block me-3">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.productEnergyVisible"
              id="productEnergyVisible"
              defaultChecked={
                form?.relationModule?.options?.productEnergyVisible
              }
              {...register("relationModule.options.productEnergyVisible")}
            />
            <Form.Check.Label htmlFor="productEnergyVisible" className="ms-2">
              Показать\Скрыть пищевую ценность
            </Form.Check.Label>
          </Form.Check>
          <hr />
          <h5 className="mb-4 fw-7">Корзина</h5>
          <Form.Check className="mb-4 d-inline-block me-3">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.cart"
              id="cart"
              defaultChecked={form?.relationModule?.options?.cart}
              {...register("relationModule.options.cart")}
            />
            <Form.Check.Label htmlFor="cart" className="ms-2">
              Вкл\Выкл корзины
            </Form.Check.Label>
          </Form.Check>
          <hr />
          <h5 className="mb-4 fw-7">Оформление заказа</h5>
          <Form.Check className="mb-4 d-inline-block me-3">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.promoVisible"
              id="promoVisible"
              defaultChecked={form?.relationModule?.options?.promoVisible}
              {...register("relationModule.options.promoVisible")}
            />
            <Form.Check.Label htmlFor="promoVisible" className="ms-2">
              Вкл\Выкл промокоды
            </Form.Check.Label>
          </Form.Check>
          <Form.Check className="mb-4 d-inline-block me-3">
            <Form.Check.Input
              type="checkbox"
              name="relationModule.options.giftVisible"
              id="giftVisible"
              defaultChecked={form?.relationModule?.options?.giftVisible}
              {...register("relationModule.options.giftVisible")}
            />
            <Form.Check.Label htmlFor="giftVisible" className="ms-2">
              Показать\Скрыть выбор подарков
            </Form.Check.Label>
          </Form.Check>
          <div className="mb-4">
            <Textarea
              label="Текст успешной отправки заказа"
              placeholder='Статус заказа вы можете узнать в личном профиле, в разделе "Заказы".'
              name="relationModule.options.checkoutSuccessText"
              errors={errors}
              register={register}
              validation={{
                maxLength: {
                  value: 1500,
                  message: "Максимально 500 символов",
                },
              }}
            />
          </div>
        </Tab>
        <Tab eventKey={1} title="Разделы">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-2 fw-7">Меню</h5>
            <div>
              <a
                onClick={() => setShowMenu((prev) => ({ ...prev, show: true }))}
                className="btn-sm btn-primary-outline"
              >
                <IoAdd size={18} />
              </a>
            </div>
          </div>
          <div className="mb-4">
            {menuData?.length > 0 ? (
              menuData
                .sort((a, b) => a.id - b.id)
                .map((e, index) => (
                  <Row
                    key={index}
                    className="py-2 border-bottom d-flex flex-row justify-content-between align-items-center"
                  >
                    <Col className="text-nowrap">
                      <p className="text-muted fs-07">Меню</p>
                      {e.id}
                    </Col>
                    <Col>
                      <p className="text-muted fs-07">Раздел</p>
                      {e.edit ? (
                        <Select
                          onClick={(e) => {
                            let data = { ...menuData };
                            data[index] = { ...data[index], ...e };
                            setValue("relationModule.options.menu", data);
                          }}
                          value={
                            form?.relationModule?.options?.menu &&
                            form?.relationModule?.options?.menu[index]?.value
                              ? form.relationModule.options.menu[index].value
                              : ""
                          }
                          data={[
                            { title: "Уведомления", value: "notifications" },
                            { title: "Категории", value: "categories" },
                          ]}
                        />
                      ) : e?.value ? (
                        e?.value
                      ) : (
                        "По умолчанию"
                      )}
                    </Col>
                    <Col className="d-flex flex-row justify-content-end align-items-center">
                      {e.edit ? (
                        <Badge bg="success">Откреплен</Badge>
                      ) : (
                        <Badge bg="secondary">Закреплен</Badge>
                      )}
                    </Col>
                  </Row>
                ))
            ) : (
              <p className="text-muted fs-09">Добавьте свои пункты меню</p>
            )}
            {!form?.relationModule?.options?.menu && (
              <p className="text-danger fs-09 mt-2">
                Не выбрана настройка для меню
              </p>
            )}
            <CustomModal
              title="Добавить пункт меню"
              show={showMenu?.show}
              setShow={(e) => setShowMenu((prev) => ({ ...prev, show: e }))}
              footer={
                <>
                  <Button
                    className=" me-3"
                    onClick={() =>
                      setShowMenu((prev) => ({ ...prev, show: false }))
                    }
                  >
                    Отмена
                  </Button>
                  <Button
                    className="btn-primary"
                    onClick={() => onCreateMenu()}
                  >
                    Сохранить
                  </Button>
                </>
              }
            >
              <Input
                className="mb-4"
                label="Текст"
                placeholder="Введите текст"
                onChange={(e) =>
                  setShowMenu((prev) => ({
                    ...prev,
                    data: { ...prev.data, title: e },
                  }))
                }
              />
              <Select
                className="mb-4"
                label="Раздел"
                onClick={(e) =>
                  setShowMenu((prev) => ({
                    ...prev,
                    data: { ...prev.data, page: e.value },
                  }))
                }
                value={showMenu?.data?.page}
                data={pageData}
              />
              <Select
                className="mb-4"
                label="Стиль"
                onClick={(e) =>
                  setShowMenu((prev) => ({
                    ...prev,
                    data: { ...prev.data, type: e.value },
                  }))
                }
                value={showMenu?.data?.type}
                data={[
                  { title: "Светлый", value: "light" },
                  { title: "Темный", value: "dark" },
                ]}
              />
              <Input
                className="mb-4"
                label="Порядок"
                type="number"
                placeholder="0"
                onChange={(e) =>
                  setShowMenu((prev) => ({
                    ...prev,
                    data: { ...prev.data, order: e },
                  }))
                }
              />
              <Input
                className="mb-4"
                label="Иконка"
                placeholder="Название иконки ionicons"
                errors={errors}
                onChange={(e) =>
                  setShowMenu((prev) => ({
                    ...prev,
                    data: { ...prev.data, icon: e },
                  }))
                }
              />
              {showMenu?.data?.page === false && (
                <Input
                  className="mb-4"
                  label="Ссылка"
                  errors={errors}
                  onChange={(e) =>
                    setShowMenu((prev) => ({
                      ...prev,
                      data: { ...prev.data, link: e },
                    }))
                  }
                />
              )}
              <div className="d-flex">
                <Form.Check className="me-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="menu-status"
                    defaultChecked={showMenu.data.status}
                    onChange={(e) =>
                      setShowMenu((prev) => ({
                        ...prev,
                        data: { ...prev.data, status: e },
                      }))
                    }
                  />
                  <Form.Check.Label htmlFor="menu-status" className="ms-2">
                    Показать\Скрыть
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    id="mobile-status"
                    defaultChecked={showMenu.data.mobile}
                    onChange={(e) =>
                      setShowMenu((prev) => ({
                        ...prev,
                        data: { ...prev.data, mobile: e },
                      }))
                    }
                  />
                  <Form.Check.Label htmlFor="mobile-status" className="ms-2">
                    Показать в моб версии
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </CustomModal>
            <CustomModal
              title="Редактировать меню"
              show={showMenuEdit?.show}
              setShow={(e) => setShowMenuEdit((prev) => ({ ...prev, show: e }))}
              footer={
                <>
                  <Button
                    className=" me-3"
                    onClick={() =>
                      setShowMenuEdit((prev) => ({ ...prev, show: false }))
                    }
                  >
                    Отмена
                  </Button>
                  <Button className="btn-primary" onClick={() => onEditMenu()}>
                    Сохранить
                  </Button>
                </>
              }
            >
              <Input
                className="mb-4"
                label="Текст"
                placeholder="Введите текст"
                onChange={(e) =>
                  setShowMenuEdit((prev) => ({
                    ...prev,
                    data: { ...prev.data, title: e },
                  }))
                }
              />
              <Select
                value={showMenuEdit.data.page}
                className="mb-4"
                label="Раздел"
                onClick={(e) =>
                  setShowMenuEdit((prev) => ({
                    ...prev,
                    data: { ...prev.data, page: e.value },
                  }))
                }
                data={pageData}
              />
              <Select
                value={showMenuEdit.data.type}
                className="mb-4"
                label="Стиль"
                onClick={(e) =>
                  setShowMenuEdit((prev) => ({
                    ...prev,
                    data: { ...prev.data, type: e.value },
                  }))
                }
                data={[
                  { title: "Светлый", value: "light" },
                  { title: "Темный", value: "dark" },
                ]}
              />
              <Input
                className="mb-4"
                label="Порядок"
                type="number"
                placeholder="0"
                onChange={(e) =>
                  setShowMenuEdit((prev) => ({
                    ...prev,
                    data: { ...prev.data, order: e },
                  }))
                }
              />
              <Input
                className="mb-4"
                label="Иконка"
                placeholder="Название иконки ionicons"
                errors={errors}
                onChange={(e) =>
                  setShowMenuEdit((prev) => ({
                    ...prev,
                    data: { ...prev.data, icon: e },
                  }))
                }
              />
              {showMenu?.data?.page === false && (
                <Input
                  className="mb-4"
                  label="Ссылка"
                  errors={errors}
                  onChange={(e) =>
                    setShowMenuEdit((prev) => ({
                      ...prev,
                      data: { ...prev.data, link: e },
                    }))
                  }
                />
              )}
              <div className="d-flex">
                <Form.Check className="me-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="menu-status-edit"
                    defaultChecked={showMenuEdit.data.status}
                    onChange={(e) =>
                      setShowMenuEdit((prev) => ({
                        ...prev,
                        data: { ...prev.data, status: e.target.checked },
                      }))
                    }
                  />
                  <Form.Check.Label htmlFor="menu-status-edit" className="ms-2">
                    Показать\Скрыть
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    id="mobile-status-edit"
                    defaultChecked={showMenuEdit.data.mobile}
                    onChange={(e) =>
                      setShowMenuEdit((prev) => ({
                        ...prev,
                        data: { ...prev.data, mobile: e.target.checked },
                      }))
                    }
                  />
                  <Form.Check.Label
                    htmlFor="mobile-status-edit"
                    className="ms-2"
                  >
                    Показать в моб версии
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </CustomModal>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-2 fw-7">Главная</h5>
            <div>
              <a
                onClick={() =>
                  setShowWidget((prev) => ({ ...prev, show: true }))
                }
                className="btn-sm btn-primary-outline"
              >
                <IoAdd size={18} />
              </a>
            </div>
          </div>
          <div className="mb-4">
            {form?.relationModule?.options?.widget?.length > 0 &&
            Array.isArray(form.relationModule.options.widget) ? (
              form.relationModule.options.widget
                .sort((a, b) => a.order - b.order)
                .map((e, index) => (
                  <Row className="py-2 border-bottom d-flex flex-row justify-content-between align-items-center">
                    <Col className="text-nowrap">
                      <p className="text-muted fs-07">Заголовок</p>
                      {e?.title?.length > 0 ? e.title : "Нет заголовка"}
                    </Col>
                    <Col>
                      <p className="text-muted fs-07">Виджет</p>
                      {e.value}
                    </Col>
                    <Col>
                      <p className="text-muted fs-07">Порядок</p>
                      {e.order ?? 0}
                    </Col>
                    <Col className="d-flex flex-row justify-content-between align-items-center">
                      <a className="me-3">
                        {e.status ? (
                          <Badge bg="success">Активно</Badge>
                        ) : (
                          <Badge bg="secondary">Архив</Badge>
                        )}
                      </a>
                      <div className="d-flex flex-row align-items-center">
                        <a
                          className="me-3"
                          onClick={() =>
                            setShowWidgetEdit((prev) => ({
                              ...prev,
                              index,
                              data: e,
                              show: true,
                            }))
                          }
                        >
                          <IoCreateOutline size={20} />
                        </a>
                        <a onClick={() => onDeleteWidget(index)}>
                          <IoTrashOutline size={20} className="text-danger" />
                        </a>
                      </div>
                    </Col>
                  </Row>
                ))
            ) : (
              <p className="text-muted fs-09">Выберите виджет из списка</p>
            )}
            <CustomModal
              title="Добавить виджет"
              show={showWidget?.show}
              setShow={(e) => setShowWidget((prev) => ({ ...prev, show: e }))}
              footer={
                <>
                  <Button
                    className=" me-3"
                    onClick={() =>
                      setShowWidget((prev) => ({ ...prev, show: false }))
                    }
                  >
                    Отмена
                  </Button>
                  <Button
                    className="btn-primary"
                    onClick={() => onCreateWidget()}
                  >
                    Сохранить
                  </Button>
                </>
              }
            >
              <Tabs
                defaultActiveKey={0}
                className="mb-3"
                classNameBody="p-0"
                fill
              >
                <Tab eventKey={0} title="Основное" className="p-3">
                  <Select
                    className="mb-4"
                    label="Виджет"
                    onClick={(e) =>
                      setShowWidget((prev) => ({
                        ...prev,
                        data: { ...prev.data, value: e.value },
                      }))
                    }
                    value={showWidget?.data?.value}
                    data={widgetData}
                  />
                  <Input
                    className="mb-4"
                    label="Заголовок"
                    placeholder="Введите заголовок"
                    onChange={(e) =>
                      setShowWidget((prev) => ({
                        ...prev,
                        data: { ...prev.data, title: e },
                      }))
                    }
                  />
                  <Textarea
                    className="mb-4"
                    label="Описание"
                    placeholder="Введите описание (Необязательно)"
                    onChange={(e) =>
                      setShowWidget((prev) => ({
                        ...prev,
                        data: { ...prev.data, desc: e },
                      }))
                    }
                  />
                  {showWidget?.data?.value == "hello" && (
                    <>
                      <Input
                        className="mb-4"
                        label="Текст кнопки"
                        placeholder="Введите текст"
                        onChange={(e) =>
                          setShowWidgetEdit((prev) => ({
                            ...prev,
                            data: { ...prev.data, btnText: e },
                          }))
                        }
                      />
                      <Input
                        className="mb-4"
                        label="Ссылка кнопки"
                        placeholder="Введите ссылку"
                        onChange={(e) =>
                          setShowWidgetEdit((prev) => ({
                            ...prev,
                            data: { ...prev.data, btnLink: e },
                          }))
                        }
                      />
                    </>
                  )}

                  <Input
                    className="mb-4"
                    label="Порядок"
                    type="number"
                    placeholder="0"
                    onChange={(e) =>
                      setShowWidget((prev) => ({
                        ...prev,
                        data: { ...prev.data, order: e },
                      }))
                    }
                  />
                  <Form.Check>
                    <Form.Check.Input
                      type="checkbox"
                      id="widget-status"
                      defaultChecked={showWidget.data.status}
                      onChange={(e) =>
                        setShowWidget((prev) => ({
                          ...prev,
                          data: { ...prev.data, status: e.target.checked },
                        }))
                      }
                    />
                    <Form.Check.Label htmlFor="widget-status" className="ms-2">
                      Показать\Скрыть
                    </Form.Check.Label>
                  </Form.Check>
                  {showWidget?.data?.value == "about" ? (
                    <div className="mt-3">
                      <DragDropFile
                        col={12}
                        title="Выберите картинку"
                        file={form.widgetAbout}
                        onChange={(e) => setValue("widgetAbout", e)}
                        onDelete={(e) => setValue("widgetAbout", e.file)}
                      />
                    </div>
                  ) : (
                    showWidget?.data?.value == "hello" && (
                      <div className="mt-3">
                        <DragDropFile
                          col={12}
                          title="Выберите фон"
                          file={form.widgetHello}
                          onChange={(e) => setValue("widgetHello", e)}
                          onDelete={(e) => setValue("widgetHello", e.file)}
                        />
                      </div>
                    )
                  )}
                </Tab>
                {brand.options?.lang?.length > 1 && (
                  <Tab eventKey={1} title="Перевод" className="p-3">
                    <Row>
                      {brand.options?.lang
                        .filter((e) => e != "ru")
                        .map((lang) => (
                          <Col md={12}>
                            <div className="mb-3 fw-6">
                              {localeData.find((e) => e.lang === lang)?.title}
                            </div>
                            <Input
                              label="Заголовок"
                              placeholder="Введите заголовок"
                              className="mb-3"
                              onChange={(e) =>
                                setShowWidget((prev) => ({
                                  ...prev,
                                  data: {
                                    ...prev.data,
                                    options: {
                                      ...(prev.data.options || {}),
                                      [lang]: {
                                        ...(prev.data.options?.[lang] || {}),
                                        title: e,
                                      },
                                    },
                                  },
                                }))
                              }
                            />
                            <Textarea
                              className="mb-3"
                              label="Описание"
                              placeholder="Введите описание (Необязательно)"
                              onChange={(e) =>
                                setShowWidget((prev) => ({
                                  ...prev,
                                  data: {
                                    ...prev.data,
                                    options: {
                                      ...(prev.data.options || {}),
                                      [lang]: {
                                        ...(prev.data.options?.[lang] || {}),
                                        desc: e,
                                      },
                                    },
                                  },
                                }))
                              }
                            />
                          </Col>
                        ))}
                    </Row>
                  </Tab>
                )}
              </Tabs>
            </CustomModal>
            <CustomModal
              title="Редактировать виджет"
              show={showWidgetEdit?.show}
              classNameBody="p-0"
              setShow={(e) =>
                setShowWidgetEdit((prev) => ({ ...prev, show: e }))
              }
              footer={
                <>
                  <Button
                    className=" me-3"
                    onClick={() =>
                      setShowWidgetEdit((prev) => ({ ...prev, show: false }))
                    }
                  >
                    Отмена
                  </Button>
                  <Button
                    className="btn-primary"
                    onClick={() => onEditWidget()}
                  >
                    Сохранить
                  </Button>
                </>
              }
            >
              <Tabs defaultActiveKey={0} className="mb-3" fill>
                <Tab eventKey={0} title="Основное" className="p-3">
                  <Select
                    className="mb-4"
                    label="Виджет"
                    onClick={(e) =>
                      setShowWidgetEdit((prev) => ({
                        ...prev,
                        data: { ...prev.data, value: e.value },
                      }))
                    }
                    value={showWidgetEdit?.data?.value}
                    data={widgetData}
                  />
                  {showWidgetEdit?.data?.value == "stories" && (
                    <Select
                      className="mb-4"
                      label="Вид"
                      onClick={(e) =>
                        setShowWidgetEdit((prev) => ({
                          ...prev,
                          data: { ...prev.data, type: e.value },
                        }))
                      }
                      value={showWidgetEdit?.data?.type ?? ""}
                      data={[
                        { title: "Круглые", value: "" },
                        { title: "Квадратные", value: "square" },
                        { title: "Прямоугольные", value: "rectangle" },
                      ]}
                    />
                  )}
                  <Input
                    className="mb-4"
                    label="Заголовок"
                    placeholder="Введите заголовок"
                    defaultValue={showWidgetEdit?.data?.title}
                    onChange={(e) =>
                      setShowWidgetEdit((prev) => ({
                        ...prev,
                        data: { ...prev.data, title: e },
                      }))
                    }
                  />
                  <Textarea
                    className="mb-4"
                    label="Описание"
                    placeholder="Введите описание (Необязательно)"
                    defaultValue={showWidgetEdit?.data?.desc}
                    onChange={(e) =>
                      setShowWidgetEdit((prev) => ({
                        ...prev,
                        data: { ...prev.data, desc: e },
                      }))
                    }
                  />
                  {showWidgetEdit?.data?.value == "hello" && (
                    <>
                      <Input
                        className="mb-4"
                        label="Текст кнопки"
                        placeholder="Введите текст"
                        defaultValue={showWidgetEdit?.data?.btnText}
                        onChange={(e) =>
                          setShowWidgetEdit((prev) => ({
                            ...prev,
                            data: { ...prev.data, btnText: e },
                          }))
                        }
                      />
                      <Input
                        className="mb-4"
                        label="Ссылка кнопки"
                        placeholder="Введите ссылку"
                        defaultValue={showWidgetEdit?.data?.btnLink}
                        onChange={(e) =>
                          setShowWidgetEdit((prev) => ({
                            ...prev,
                            data: { ...prev.data, btnLink: e },
                          }))
                        }
                      />
                    </>
                  )}

                  <Input
                    className="mb-4"
                    label="Порядок"
                    type="number"
                    placeholder="0"
                    defaultValue={showWidgetEdit?.data?.order}
                    onChange={(e) =>
                      setShowWidgetEdit((prev) => ({
                        ...prev,
                        data: { ...prev.data, order: e },
                      }))
                    }
                  />
                  <Form.Check>
                    <Form.Check.Input
                      type="checkbox"
                      id="widget-status-edit"
                      defaultChecked={showWidgetEdit.data.status}
                      onChange={(e) =>
                        setShowWidgetEdit((prev) => ({
                          ...prev,
                          data: { ...prev.data, status: e.target.checked },
                        }))
                      }
                    />
                    <Form.Check.Label
                      htmlFor="widget-status-edit"
                      className="ms-2"
                    >
                      Показать\Скрыть
                    </Form.Check.Label>
                  </Form.Check>
                  {showWidgetEdit?.data?.value == "about" ? (
                    <div className="mt-3">
                      <DragDropFile
                        col={12}
                        title="Выберите картинку"
                        file={form.widgetAbout}
                        onChange={(e) => setValue("widgetAbout", e)}
                        onDelete={(e) => setValue("widgetAbout", e.file)}
                      />
                    </div>
                  ) : (
                    showWidgetEdit?.data?.value == "hello" && (
                      <div className="mt-3">
                        <DragDropFile
                          col={12}
                          title="Выберите фон"
                          file={form.widgetHello}
                          onChange={(e) => setValue("widgetHello", e)}
                          onDelete={(e) => setValue("widgetHello", e.file)}
                        />
                      </div>
                    )
                  )}
                </Tab>
                {brand.options?.lang?.length > 1 && (
                  <Tab eventKey={1} title="Перевод" className="p-3">
                    <Row>
                      {brand.options?.lang
                        .filter((e) => e != "ru")
                        .map((lang) => (
                          <Col md={12}>
                            <div className="mb-3 fw-6">
                              {localeData.find((e) => e.lang === lang)?.title}
                            </div>
                            <Input
                              label="Заголовок"
                              placeholder="Введите заголовок"
                              className="mb-3"
                              defaultValue={
                                showWidgetEdit?.data?.options?.[lang]?.title
                              }
                              onChange={(e) =>
                                setShowWidgetEdit((prev) => ({
                                  ...prev,
                                  data: {
                                    ...prev.data,
                                    options: {
                                      ...(prev.data.options || {}),
                                      [lang]: {
                                        ...(prev.data.options?.[lang] || {}),
                                        title: e,
                                      },
                                    },
                                  },
                                }))
                              }
                            />

                            <Textarea
                              className="mb-3"
                              label="Описание"
                              placeholder="Введите описание (Необязательно)"
                              defaultValue={
                                showWidgetEdit?.data?.options?.[lang]?.desc
                              }
                              onChange={(e) =>
                                setShowWidgetEdit((prev) => ({
                                  ...prev,
                                  data: {
                                    ...prev.data,
                                    options: {
                                      ...(prev.data.options || {}),
                                      [lang]: {
                                        ...(prev.data.options?.[lang] || {}),
                                        desc: e,
                                      },
                                    },
                                  },
                                }))
                              }
                            />
                          </Col>
                        ))}
                    </Row>
                  </Tab>
                )}
              </Tabs>
            </CustomModal>
          </div>
          {(brand?.type == "shop" || brand?.type == "service") && (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className="mb-2 fw-7">Проект</h5>
              </div>
              <div className="mb-4">
                <Row className="py-3 border-bottom d-flex flex-row justify-content-between align-items-center">
                  <Col>
                    <Input
                      label="Текст кнопки"
                      onChange={(e) =>
                        setValue(
                          "relationModule.options.project.buttons.0.title",
                          e
                        )
                      }
                      placeholder="Введите текст"
                    />
                  </Col>
                  <Col>
                    <Select
                      label="Стиль"
                      onClick={(e) =>
                        setValue(
                          "relationModule.options.project.buttons.0.type",
                          e.value
                        )
                      }
                      value={
                        form?.relationModule?.options?.project?.buttons[0]?.type
                      }
                      data={[
                        { title: "Светлый", value: "light" },
                        { title: "Темный", value: "dark" },
                      ]}
                    />
                  </Col>
                  <Col className="d-flex flex-row justify-content-between align-items-center">
                    <Form.Check>
                      <Form.Check.Input
                        type="checkbox"
                        id="project-button-0"
                        defaultChecked={showMenu.data.status}
                        onChange={(e) =>
                          setValue(
                            "relationModule.options.project.buttons.0.status",
                            e.target.checked
                          )
                        }
                      />
                      <Form.Check.Label
                        htmlFor="project-button-0"
                        className="ms-2"
                      >
                        Показать\Скрыть
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                </Row>
                <Row className="py-3 border-bottom d-flex flex-row justify-content-between align-items-center">
                  <Col>
                    <Input
                      label="Текст кнопки"
                      onChange={(e) =>
                        setValue(
                          "relationModule.options.project.buttons.1.title",
                          e
                        )
                      }
                      placeholder="Введите текст"
                    />
                  </Col>
                  <Col>
                    <Select
                      label="Стиль"
                      onClick={(e) =>
                        setValue(
                          "relationModule.options.project.buttons.1.type",
                          e.value
                        )
                      }
                      value={
                        form?.relationModule?.options?.project?.buttons[1]?.type
                      }
                      data={[
                        { title: "Светлый", value: "light" },
                        { title: "Темный", value: "dark" },
                      ]}
                    />
                  </Col>
                  <Col className="d-flex flex-row justify-content-between align-items-center">
                    <Form.Check>
                      <Form.Check.Input
                        type="checkbox"
                        id="project-button-1"
                        defaultChecked={showMenu.data.status}
                        onChange={(e) =>
                          setValue(
                            "relationModule.options.project.buttons.1.status",
                            e.target.checked
                          )
                        }
                      />
                      <Form.Check.Label
                        htmlFor="project-button-1"
                        className="ms-2"
                      >
                        Показать\Скрыть
                      </Form.Check.Label>
                    </Form.Check>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Tab>
        <Tab eventKey={3} title="Оплата и заявки">
          <Row>
            <Col md={4}>
              <div className="border rounded-3 mb-3 p-3 bg-light">
                <p className="mb-3 fw-6 border-bottom pb-3 d-flex align-items-center justify-content-between">
                  Доставка
                  <Form.Check
                    id="status-delivery"
                    type="switch"
                    className="switch"
                    defaultChecked={
                      form?.relationModule?.options?.dellivery?.status
                    }
                    {...register("relationModule.options.delivery.status")}
                  />
                </p>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="online-delivery"
                    defaultChecked={
                      form?.relationModule?.options?.delivery?.online
                    }
                    {...register("relationModule.options.delivery.online")}
                  />
                  <Form.Check.Label htmlFor="online-delivery" className="ms-2">
                    Онлайн оплата
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="card-delivery"
                    defaultChecked={
                      form?.relationModule?.options?.delivery?.card
                    }
                    {...register("relationModule.options.delivery.card")}
                  />
                  <Form.Check.Label htmlFor="card-delivery" className="ms-2">
                    Банковской картой
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="cash-delivery"
                    defaultChecked={
                      form?.relationModule?.options?.delivery?.cash
                    }
                    {...register("relationModule.options.delivery.cash")}
                  />
                  <Form.Check.Label htmlFor="cash-delivery" className="ms-2">
                    Наличными
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </Col>
            <Col md={4}>
              <div className="border rounded-3 mb-3 p-3 bg-light">
                <p className="mb-3 border-bottom pb-3 fw-6 d-flex align-items-center justify-content-between">
                  Самовывоз
                  <Form.Check
                    id="status-pickup"
                    type="switch"
                    className="switch"
                    defaultChecked={
                      form?.relationModule?.options?.pickup?.status
                    }
                    {...register("relationModule.options.pickup.status")}
                  />
                </p>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="online-pickup"
                    defaultChecked={
                      form?.relationModule?.options?.pickup?.online
                    }
                    {...register("relationModule.options.pickup.online")}
                  />
                  <Form.Check.Label htmlFor="online-pickup" className="ms-2">
                    Онлайн оплата
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="card-pickup"
                    defaultChecked={form?.relationModule?.options?.pickup?.card}
                    {...register("relationModule.options.pickup.card")}
                  />
                  <Form.Check.Label htmlFor="card-pickup" className="ms-2">
                    Банковской картой
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="cash-pickup"
                    defaultChecked={form?.relationModule?.options?.pickup?.cash}
                    {...register("relationModule.options.pickup.cash")}
                  />
                  <Form.Check.Label htmlFor="cash-pickup" className="ms-2">
                    Наличными
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </Col>
            <Col md={4}>
              <div className="border rounded-3 mb-3 p-3 bg-light">
                <p className="mb-3 fw-6 border-bottom pb-3 d-flex align-items-center justify-content-between">
                  В зале
                  <Form.Check
                    id="status-hall"
                    type="switch"
                    className="switch"
                    defaultChecked={form?.relationModule?.options?.hall?.status}
                    {...register("relationModule.options.hall.status")}
                  />
                </p>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="online-hall"
                    defaultChecked={form?.relationModule?.options?.hall?.online}
                    {...register("relationModule.options.hall.online")}
                  />
                  <Form.Check.Label htmlFor="online-hall" className="ms-2">
                    Онлайн оплата
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="card-hall"
                    defaultChecked={form?.relationModule?.options?.hall?.card}
                    {...register("relationModule.options.hall.card")}
                  />
                  <Form.Check.Label htmlFor="card-hall" className="ms-2">
                    Банковской картой
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check className="mb-3 d-inline-block me-3">
                  <Form.Check.Input
                    type="checkbox"
                    id="cash-hall"
                    defaultChecked={form?.relationModule?.options?.hall?.cash}
                    {...register("relationModule.options.hall.cash")}
                  />
                  <Form.Check.Label htmlFor="cash-hall" className="ms-2">
                    Наличными
                  </Form.Check.Label>
                </Form.Check>
              </div>
            </Col>
            {brand?.type === "service" && (
              <Col md={4}>
                <div className="border rounded-3 mb-3 p-3 bg-light">
                  <p className="mb-3 fw-6 border-bottom pb-3 d-flex align-items-center justify-content-between">
                    Обратная связь
                    <Form.Check
                      id="status-feedback"
                      type="switch"
                      className="switch"
                      defaultChecked={
                        form?.relationModule?.options?.feedback?.status
                      }
                      {...register("relationModule.options.feedback.status")}
                    />
                  </p>
                  <span className="text-muted fs-09">Настроек пока нет</span>
                </div>
              </Col>
            )}
          </Row>
        </Tab>
        <Tab eventKey={4} title="Тема">
          <Row>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Тема"
                  onClick={(e) =>
                    setValue("relationModule.options.themeType", e.value)
                  }
                  value={form?.relationModule?.options?.themeType ?? false}
                  data={syncThemeTypeData}
                />
              </div>
            </Col>
          </Row>
          <h5 className="mb-4">Цвета для светлой версии</h5>
          <Row>
            <Col md={12} className="d-flex flex-wrap">
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Основное</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.colorMain}
                  onChange={(e) =>
                    setValue("relationModule.options.colorMain", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.colorMain", e)
                  }
                  value={form?.relationModule?.options?.colorMain}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Кнопка</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.colorBtn}
                  onChange={(e) =>
                    setValue("relationModule.options.colorBtn", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.colorBtn", e)
                  }
                  value={form?.relationModule?.options?.colorBtn}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Навигация</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.colorNavigation}
                  onChange={(e) =>
                    setValue("relationModule.options.colorNavigation", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.colorNavigation", e)
                  }
                  value={form?.relationModule?.options?.colorNavigation}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Текст</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.colorText}
                  onChange={(e) =>
                    setValue("relationModule.options.colorText", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.colorText", e)
                  }
                  value={form?.relationModule?.options?.colorText}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Заголовок</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.colorTextTitle}
                  onChange={(e) =>
                    setValue("relationModule.options.colorTextTitle", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.colorTextTitle", e)
                  }
                  value={form?.relationModule?.options?.colorTextTitle}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Подзаголовок</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.colorTextSubtitle}
                  onChange={(e) =>
                    setValue("relationModule.options.colorTextSubtitle", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.colorTextSubtitle", e)
                  }
                  value={form?.relationModule?.options?.colorTextSubtitle}
                />
              </div>
            </Col>
          </Row>
          <h5 className="mb-4">Цвета для темной версии</h5>
          <Row>
            <Col md={12} className="d-flex flex-wrap">
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Основное</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.darkColorMain}
                  onChange={(e) =>
                    setValue("relationModule.options.darkColorMain", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.darkColorMain", e)
                  }
                  value={form?.relationModule?.options?.darkColorMain}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Кнопка</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.darkColorBtn}
                  onChange={(e) =>
                    setValue("relationModule.options.darkColorBtn", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.darkColorBtn", e)
                  }
                  value={form?.relationModule?.options?.darkColorBtn}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Навигация</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.darkColorNavigation}
                  onChange={(e) =>
                    setValue("relationModule.options.darkColorNavigation", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.darkColorNavigation", e)
                  }
                  value={form?.relationModule?.options?.darkColorNavigation}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Текст</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.darkColorText}
                  onChange={(e) =>
                    setValue("relationModule.options.darkColorText", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.darkColorText", e)
                  }
                  value={form?.relationModule?.options?.darkColorText}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Заголовок</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.darkColorTextTitle}
                  onChange={(e) =>
                    setValue("relationModule.options.darkColorTextTitle", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.darkColorTextTitle", e)
                  }
                  value={form?.relationModule?.options?.darkColorTextTitle}
                />
              </div>
              <div className="mb-4 px-3 colorful d-flex justify-content-center align-items-center flex-column">
                <p className="mb-3 fw-6 text-center fs-08">Подзаголовок</p>
                <HexAlphaColorPicker
                  color={form?.relationModule?.options?.darkColorTextSubtitle}
                  onChange={(e) =>
                    setValue("relationModule.options.darkColorTextSubtitle", e)
                  }
                />
                <Input
                  className="mt-3"
                  onChange={(e) =>
                    setValue("relationModule.options.darkColorTextSubtitle", e)
                  }
                  value={form?.relationModule?.options?.darkColorTextSubtitle}
                />
              </div>
            </Col>
          </Row>
          <h5 className="mb-4">Товары</h5>
          <Row>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Вид товара"
                  onClick={(e) =>
                    setValue("relationModule.options.themeProduct", e.value)
                  }
                  value={form?.relationModule?.options?.themeProduct ?? 0}
                  data={syncThemeProduct}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Вид ряда"
                  onClick={(e) =>
                    setValue(
                      "relationModule.options.themeProductColumn",
                      e.value
                    )
                  }
                  value={form?.relationModule?.options?.themeProductColumn ?? 0}
                  data={syncProductColumn}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-4">
                <Select
                  label="Вид допов"
                  onClick={(e) =>
                    setValue("relationModule.options.themeAddition", e.value)
                  }
                  value={form?.relationModule?.options?.themeAddition ?? 0}
                  data={syncThemeAddition}
                />
              </div>
            </Col>
          </Row>
        </Tab>
      </Tabs>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <span className="text-muted fs-08 me-3">
          Данные применяться в течении 1 мин
        </span>
        <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
    </Card>
  );
};
export default AppForm;
