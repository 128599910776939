import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getTerminals = async (data) => {
  const response = await $authApi.get(apiRoutes.TERMINALS, {
    params: data,
  });
  return response?.data;
};

const getTerminal = async (id) => {
  const response = await $authApi.get(apiRoutes.TERMINAL, {
    params: {
      id,
    },
  });

  return response?.data;
};

const createTerminal = async (data) => {
  const response = await $authApi.post(apiRoutes.TERMINALS, data);
  return response?.data;
};

const editTerminal = async (data) => {
  const response = await $authApi.put(apiRoutes.TERMINALS, data);
  return response?.data;
};

const deleteTerminal = async (ids) => {
  const response = await $authApi.delete(apiRoutes.TERMINALS, {
    data: { ids },
  });
  return response?.data;
};

export {
  getTerminals,
  getTerminal,
  createTerminal,
  editTerminal,
  deleteTerminal,
};
