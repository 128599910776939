import { useCallback, useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import Input from "../../../components/UI/Input";
import Button from "../../../components/UI/Button";
import { Card, Col, Form, Row } from "react-bootstrap";
import { IoRefreshOutline } from "react-icons/io5";
import { getStatistics } from "../../../services/statistic";
import {
  getEprCategory,
  getEprDiscounts,
  getEprModifier,
  getEprOrganizations,
  getEprProduct,
  getEprTerminal,
  getEprTypesDelivery,
  getEprTypesPayment,
  getEprMenu,
  getEprAll,
  getEprCoupons,
  getEprTable,
} from "../../../services/upload";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "../../../components/UI/Select";
import socket from "../../../config/socket";
import { NotificationManager } from "react-notifications";

const initalData = {
  app_client_id: null,
  app_secret: null,
  secret_key: null,
  organizations: [],
};

const PrestoForm = ({ data, onSubmit, onUpdate, setInitalData }) => {
  const brand = useSelector((state) => state?.brand?.active);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: data ?? initalData,
  });

  const form = useWatch({ control });

  const [all, setAll] = useState(false);

  const [statistic, setStatistic] = useState({
    loading: true,

    categories: 0,
    products: 0,
    modifiers: 0,
    tables: 0,

    organizations: 0,
    discounts: 0,
    typesDelivery: 0,
    typesPayment: 0,
    terminal: 0,
  });

  const updateStatistics = () => {
    getStatistics({
      sync: true,
      category: true,
      table: true,
      product: true,
      modifier: true,
    })
      .then((res) => {
        onUpdate();
        setStatistic({ ...statistic, ...res, loading: false });
      })
      .catch(() => setStatistic({ ...statistic, loading: false }));
  };

  const clickGetErpProduct = useCallback(() => {
    getEprProduct(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpModifier = useCallback(() => {
    getEprModifier(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpTable = useCallback(() => {
    getEprTable(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpCategory = useCallback(() => {
    getEprCategory(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpOrganization = useCallback(() => {
    getEprOrganizations(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpCoupon = useCallback(() => {
    getEprCoupons(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpDiscount = useCallback(() => {
    getEprDiscounts(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpTypesDelivery = useCallback(() => {
    getEprTypesDelivery(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpTypesPayment = useCallback(() => {
    getEprTypesPayment(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpTerminal = useCallback(() => {
    getEprTerminal(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpMenu = useCallback(() => {
    getEprMenu(form).catch((error) => {
      NotificationManager.error(
        typeof error?.response?.data?.error == "string"
          ? error.response.data.error
          : "Неизвестная ошибка"
      );
    });
  }, [form]);

  const clickGetErpAll = useCallback(() => {
    setAll(true);
    getEprAll(form)
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      })
      .finally(() => setAll(false));
  }, [form]);

  useEffect(() => {
    setInitalData(initalData);
    updateStatistics();

    socket.on("module/presto", (data) => {
      if (data) {
        setValue("relationModule.options.status", data);
        if (
          data?.category?.status == "end" ||
          data?.product?.status == "end" ||
          data?.modifier?.status == "end" ||
          data?.organization?.status == "end" ||
          data?.terminal?.status == "end" ||
          data?.discount?.status == "end" ||
          data?.menu?.status == "end" ||
          data?.delivery?.status == "end" ||
          data?.payment?.status == "end"
        ) {
          updateStatistics();
        }
      }
    });

    return () => {
      socket.off("module/presto");
    };
  }, [brand]);

  useEffect(() => {
    data && reset(data);
  }, [data]);

  return (
    <>
      <Card body className="mb-5">
        <h5 className="mb-4">Настройки</h5>
        <Row>
          <Col lg={4}>
            <div className="mb-3">
              <Input
                label="ID Приложения"
                placeholder="Например 5217839928983"
                name="relationModule.options.app_client_id"
                errors={errors}
                defaultValue={form?.relationModule?.options?.app_client_id}
                register={register}
                validation={{ required: "Обязательное поле" }}
              />
              <p className="text-muted fs-07 mt-1">
                Выдается в системе Сбис при создании приложения
              </p>
            </div>
          </Col>
          <Col lg={4}>
            <div className="mb-3">
              <Input
                label="Защищенный ключ"
                placeholder="Например KASKIKFP142OOAOF"
                name="relationModule.options.app_secret"
                errors={errors}
                defaultValue={form?.relationModule?.options?.app_secret}
                register={register}
                validation={{ required: "Обязательное поле" }}
              />
              <p className="text-muted fs-07 mt-1">
                Выдается в системе Сбис при создании приложения
              </p>
            </div>
          </Col>
          <Col lg={4}>
            <div className="mb-3">
              <Input
                label="Cервисный ключ"
                placeholder="Например gkljdasklg239823rjjasdgkj..."
                name="relationModule.options.secret_key"
                errors={errors}
                defaultValue={form?.relationModule?.options?.secret_key}
                register={register}
                validation={{ required: "Обязательное поле" }}
              />
              <p className="text-muted fs-07 mt-1">
                Выдается в системе Сбис при создании приложения
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <h5 className="mt-3">Уведомления (callback)</h5>
          <Col lg={12}>
            <div className="mb-4 mt-4">
              <Input
                label="Ссылка для уведомлений (callback)"
                readOnly={false}
                defaultValue={`${
                  process.env.REACT_APP_WEBHOOK_URL
                }/accounting/${brand?.id ?? ""}/`}
              />
              <p className="text-muted fs-07 mt-1">
                Данную ссылку нужно указать в настройках интеграции.
              </p>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-end">
          <Button isValid={isValid} onClick={handleSubmit(onSubmit)}>
            Сохранить изменения
          </Button>
        </div>
      </Card>
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <h5 className="fw-7">Синхронизация</h5>
      </div>
      <Row>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Категории</h5>
              <Button
                disabled={
                  !form?.relationModule?.options?.login ||
                  form?.relationModule?.options?.status?.category?.status ==
                    "process"
                }
                onClick={() => clickGetErpCategory()}
                className="btn btn-sm btn-default"
              >
                {!form?.relationModule?.options?.status?.category?.status ||
                form?.relationModule?.options?.status?.category?.status ==
                  "end" ? (
                  <IoRefreshOutline size={22} className="text-gray" />
                ) : (
                  form?.relationModule?.options?.status?.category?.text ??
                  "Выгрузка данных..."
                )}
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.categories}
            </h2>
            <div className="mt-2">
              <Link
                to="/catalog/categories"
                disabled={!form?.relationModule?.options?.login}
                className="btn-sm btn-primary-outline"
              >
                Список
              </Link>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Товары</h5>
              <Button
                disabled={
                  !form?.relationModule?.options?.app_client_id ||
                  !form?.relationModule?.options?.app_secret ||
                  !form?.relationModule?.options?.secret_key ||
                  form?.relationModule?.options?.status?.product?.status ==
                    "process"
                }
                onClick={() => clickGetErpProduct()}
                className="btn btn-sm btn-default"
              >
                {!form?.relationModule?.options?.status?.product?.status ||
                form?.relationModule?.options?.status?.product?.status ==
                  "end" ? (
                  <IoRefreshOutline size={22} className="text-gray" />
                ) : (
                  form?.relationModule?.options?.status?.product?.text ??
                  "Выгрузка данных..."
                )}
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.products}
            </h2>
            <div className="mt-2 d-flex flex-column">
              <Link
                to="/catalog/products"
                disabled={!form?.relationModule?.options?.login}
                className="btn-sm btn-primary-outline"
              >
                Список
              </Link>
              <Form.Check className="mt-2 mb-0">
                <Form.Check.Input
                  type="checkbox"
                  name="productImage"
                  className="btn-sm"
                  id="product-image"
                  {...register("productImage")}
                />
                <Form.Check.Label
                  htmlFor="product-image"
                  className="ms-2 fs-09"
                >
                  С картинками
                </Form.Check.Label>
              </Form.Check>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Модификаторы</h5>
              <Button
                disabled={
                  !form?.relationModule?.options?.login ||
                  form?.relationModule?.options?.status?.modifier?.status ==
                    "process"
                }
                onClick={() => clickGetErpModifier()}
                className="btn btn-sm btn-default"
              >
                {!form?.relationModule?.options?.status?.modifier?.status ||
                form?.relationModule?.options?.status?.modifier?.status ==
                  "end" ? (
                  <IoRefreshOutline size={22} className="text-gray" />
                ) : (
                  form?.relationModule?.options?.status?.modifier?.text ??
                  "Выгрузка данных..."
                )}
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.modifiers}
            </h2>
            <div className="mt-2">
              <Link
                to="/catalog/modifiers"
                disabled={!form?.relationModule?.options?.login}
                className="btn-sm btn-primary-outline"
              >
                Список
              </Link>
            </div>
          </Card>
        </Col>
        {Number(statistic?.additions) > 0 && (
          <Col md={4}>
            <Card body className="mb-4">
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="mb-2 fw-6 h6">Добавки</h5>
              </div>
              <h2 className="m-0 d-flex align-items-center">
                {statistic.additions}
              </h2>
              <div className="mt-2">
                <Link
                  to="/catalog/additions"
                  className="btn-sm btn-primary-outline"
                >
                  Список
                </Link>
              </div>
            </Card>
          </Col>
        )}
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Столы</h5>
              <Button
                disabled={
                  !form?.relationModule?.options?.login ||
                  form?.relationModule?.options?.status?.table?.status ==
                    "process"
                }
                onClick={() => clickGetErpTable()}
                className="btn btn-sm btn-default"
              >
                {!form?.relationModule?.options?.status?.table?.status ||
                form?.relationModule?.options?.status?.table?.status ==
                  "end" ? (
                  <IoRefreshOutline size={22} className="text-gray" />
                ) : (
                  form?.relationModule?.options?.status?.table?.text ??
                  "Выгрузка данных..."
                )}
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {statistic.tables}
            </h2>
            <div className="mt-2">
              <Link
                to="/options/affiliates/tables"
                disabled={!form?.relationModule?.options?.login}
                className="btn-sm btn-primary-outline"
              >
                Список
              </Link>
            </div>
          </Card>
        </Col>
      </Row>

      <h5 className="my-4 fw-7">Данные для заказа</h5>
      <Row>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Организации</h5>
              <Button
                disabled={
                  !form?.relationModule?.options?.app_client_id ||
                  !form?.relationModule?.options?.app_secret ||
                  !form?.relationModule?.options?.secret_key ||
                  form?.relationModule?.options?.status?.organization?.status ==
                    "process"
                }
                onClick={() => clickGetErpOrganization()}
                className="btn btn-sm btn-default"
              >
                {!form?.relationModule?.options?.status?.organization?.status ||
                form?.relationModule?.options?.status?.organization?.status ==
                  "end" ? (
                  <IoRefreshOutline size={22} className="text-gray" />
                ) : (
                  form?.relationModule?.options?.status?.organization?.text ??
                  "Выгрузка данных..."
                )}
              </Button>
            </div>
            <h2 className="m-0 d-flex align-items-center">
              {form?.relationModule &&
              Array.isArray(form?.relationModule?.options?.organizations)
                ? form.relationModule.options.organizations.length
                : 0}
            </h2>
            <div className="body-scroll">
              {form?.relationModule &&
                Array.isArray(form?.relationModule?.options?.organizations) &&
                form?.relationModule?.options?.organizations &&
                form?.relationModule?.options?.organizations.map(
                  (item, index) => (
                    <div className="mt-3 d-flex align-items-center">
                      <div className="me-3">
                        <Form.Check.Input
                          type="checkbox"
                          name="main"
                          id="main-org"
                          defaultChecked={item?.main}
                          onClick={() => {
                            // form.relationModule.options.organizations =
                            //   form.relationModule.options.organizations.map(
                            //     (e) => {
                            //       e.main = false;
                            //       return e;
                            //     }
                            //   );

                            form.relationModule.options.organizations[
                              index
                            ].main = !!!item.main;
                            setValue(
                              "relationModule.options.organizations",
                              form.relationModule.options.organizations
                            );
                          }}
                        />
                      </div>
                      <div>
                        <p className="fs-07 text-muted">{item.id}</p>
                        <p className="fs-09">
                          <span>{item.name}</span>
                          <span>{item.orderServiceType}</span>
                        </p>
                      </div>
                    </div>
                  )
                )}
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card body className="mb-4">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="mb-2 fw-6 h6">Меню</h5>
              <Button
                disabled={
                  !form?.relationModule?.options?.app_client_id ||
                  !form?.relationModule?.options?.app_secret ||
                  !form?.relationModule?.options?.secret_key ||
                  form?.relationModule?.options?.status?.menu?.status ==
                    "process"
                }
                onClick={() => clickGetErpMenu()}
                className="btn btn-sm btn-default"
              >
                {!form?.relationModule?.options?.status?.menu?.status ||
                form?.relationModule?.options?.status?.menu?.status == "end" ? (
                  <IoRefreshOutline size={22} className="text-gray" />
                ) : (
                  form?.relationModule?.options?.status?.menu?.text ??
                  "Выгрузка данных..."
                )}
              </Button>
            </div>

            <h2 className="m-0 d-flex align-items-center">
              {form?.relationModule &&
              Array.isArray(form?.relationModule?.options?.menus)
                ? form.relationModule.options.menus.length
                : 0}
            </h2>
            <div className="body-scroll">
              {form?.relationModule &&
              Array.isArray(form?.relationModule?.options?.menus) &&
              form?.relationModule?.options?.menus?.length > 0 ? (
                form?.relationModule?.options?.menus.map((item, index) => (
                  <div className="mt-3 d-flex align-items-center">
                    <div className="me-3">
                      <Form.Check.Input
                        type="radio"
                        name="menu_main"
                        id="main-menu"
                        defaultChecked={item?.main}
                        onClick={() => {
                          form.relationModule.options.menus =
                            form.relationModule.options.menus.map((e) => {
                              if (e.id == item.id) {
                                e.main = !!!item.main;
                              } else {
                                e.main = false;
                              }
                              return e;
                            });
                          setValue(
                            "relationModule.options.menus",
                            form.relationModule.options.menus
                          );
                        }}
                      />
                    </div>
                    <div>
                      <p className="fs-07 text-muted">{item.id}</p>
                      <div>{item.name}</div>
                    </div>
                  </div>
                ))
              ) : (
                <span className="text-muted fs-09">Нет данных</span>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default PrestoForm;
