import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getCities = async (data) => {
  const response = await $authApi.get(apiRoutes.CITIES, {
    params: data,
  });
  return response?.data;
};

const getCity = async (id) => {
  const response = await $authApi.get(apiRoutes.CITY, {
    params: {
      id,
    },
  });
  return response?.data;
};

const editCity = async (data) => {
  const response = await $authApi.put(apiRoutes.CITIES, data);
  return response?.data;
};

const deleteCity = async (id) => {
  const response = await $authApi.delete(apiRoutes.CITIES, {
    data: { id },
  });
  return response?.data;
};

const createCity = async (data) => {
  const response = await $authApi.post(apiRoutes.CITIES, data);
  return response?.data;
};

export {
  getCities,
  getCity,
  editCity,
  deleteCity,
  createCity,
};

