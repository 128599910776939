import React, { memo } from "react";
import { Badge } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { useSelector } from "react-redux";
import { getImageURL } from "../helpers/image";
import { useTranslation } from "react-i18next";

const AdminMenu = memo(({ close }) => {
  const notification = useSelector((state) => state.notification);
  const brand = useSelector((state) => state.brand.active);
  const optionsBrand = useSelector((state) => state.settings.brand);
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="w-100">
      <Link
        to="/"
        className="fs-12 fw-7 p-2 py-3 d-flex align-items-center logo"
      >
        <img
          src={
            optionsBrand?.logo
              ? getImageURL({
                  path: optionsBrand.logo,
                  type: "all/brand/logo",
                  size: "full",
                })
              : "/logo.png"
          }
          height={35}
        />

        <div className="ms-3 fw-8 d-flex d-lg-none d-xl-flex align-items-center justify-content-between w-100">
          <div className="fs-09">{optionsBrand?.title ?? "YOOAPP"}</div>
          {!optionsBrand?.title && (
            <>
              {brand?.type ? (
                <Badge
                  bg={
                    brand?.type == "shop" || brand?.type == "service"
                      ? "primary"
                      : "success"
                  }
                  className="ms-1 fw-7"
                >
                  {brand.type.toUpperCase()}
                </Badge>
              ) : (
                "APP"
              )}
            </>
          )}
        </div>
      </Link>
      <nav className="account-menu position-sticky top-1 mb-4">
        <ul>
          <li className="d-flex d-lg-none d-xl-flex">
            <p className="nav-label">{t("Общее")}</p>
          </li>
          {/* <li>
            <NavLink to="/" end>
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M21.1808 16.9703C20.8971 17.6255 20.2225 18 19.5086 18H14.8154C14.8462 17.9145 14.8735 17.8269 14.8971 17.7373C15.1709 16.6974 14.8825 15.639 14.2214 14.8963C14.4654 12.9091 14.6177 10.8733 14.7108 9.26516C14.7569 8.46731 13.7795 8.20081 13.4274 8.91526C12.7178 10.3553 11.8493 12.1958 11.0842 14.041C10.1467 14.3479 9.3768 15.1177 9.10295 16.1576C8.93642 16.7899 8.97782 17.4291 9.18451 18H4.49141C3.77747 18 3.10288 17.6255 2.81918 16.9703C2.29212 15.7533 2 14.4108 2 13C2 7.47715 6.47715 3 12 3C17.5229 3 22 7.47715 22 13C22 14.4108 21.7079 15.7533 21.1808 16.9703Z"
                  />
                  <path d="M14.7108 9.26516C14.7569 8.46731 13.7795 8.20081 13.4274 8.91526C12.7178 10.3553 11.8493 12.1958 11.0842 14.041C10.1467 14.3479 9.3768 15.1177 9.10295 16.1576C8.6742 17.7856 9.62375 19.459 11.2238 19.8953C12.8238 20.3315 14.4684 19.3654 14.8971 17.7373C15.1709 16.6974 14.8825 15.639 14.2214 14.8963C14.4654 12.9091 14.6177 10.8733 14.7108 9.26516Z" />
                </svg>
                <span>Главная</span>
              </span>
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="/"
              onClick={(e) => {
                e.preventDefault();
                close && close();
                navigate("/");
              }}
              end
            >
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M4.32992 6C3.84871 6 3.43524 6.34385 3.34854 6.8172C1.98294 14.2724 1.75 18 1.75 18H22.25C22.25 18 22.017 14.2724 20.6514 6.8172C20.5647 6.34385 20.1513 6 19.67 6H4.32992Z"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M22.25 18H1.75C1.75 18 1.75 18.695 1.80122 19.6754C1.87282 21.0459 2.95622 22.0861 4.32766 22.138C5.855 22.1959 8.2782 22.25 12 22.25C15.7218 22.25 18.145 22.1959 19.6723 22.138C21.0438 22.0861 22.1272 21.0459 22.1988 19.6754C22.25 18.695 22.25 18 22.25 18Z"
                  />
                  <path d="M12 1C9.2386 1 7 3.23857 7 6H9C9 4.34314 10.3432 3 12 3C13.6568 3 15 4.34314 15 6H17C17 3.23857 14.7614 1 12 1Z" />
                </svg>
                <span className="d-flex d-lg-none d-xl-flex">
                  {t("Заказы")}
                </span>
              </span>
              {notification?.order > 0 && (
                <Badge className="ms-2 d-flex d-lg-none d-xl-flex" bg="danger">
                  {notification.order}
                </Badge>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="users"
              onClick={(e) => {
                e.preventDefault();
                close && close();
                navigate("/users");
              }}
            >
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M2.28099 19.6575C2.36966 20.5161 2.93261 21.1957 3.77688 21.3755C5.1095 21.6592 7.6216 22 12 22C16.3784 22 18.8905 21.6592 20.2232 21.3755C21.0674 21.1957 21.6304 20.5161 21.719 19.6575C21.8505 18.3844 22 16.0469 22 12C22 7.95305 21.8505 5.6156 21.719 4.34251C21.6304 3.48389 21.0674 2.80424 20.2231 2.62451C18.8905 2.34081 16.3784 2 12 2C7.6216 2 5.1095 2.34081 3.77688 2.62451C2.93261 2.80424 2.36966 3.48389 2.28099 4.34251C2.14952 5.6156 2 7.95305 2 12C2 16.0469 2.14952 18.3844 2.28099 19.6575Z"
                  />
                  <path d="M13.9382 13.8559C15.263 13.1583 16.1663 11.768 16.1663 10.1666C16.1663 7.8655 14.3008 6 11.9996 6C9.69841 6 7.83291 7.8655 7.83291 10.1666C7.83291 11.768 8.73626 13.1584 10.0612 13.856C8.28691 14.5319 6.93216 16.1092 6.51251 18.0529C6.45446 18.3219 6.60246 18.5981 6.87341 18.6471C7.84581 18.8231 9.45616 19 12.0006 19C14.545 19 16.1554 18.8231 17.1278 18.6471C17.3977 18.5983 17.5454 18.3231 17.4876 18.0551C17.0685 16.1103 15.7133 14.5321 13.9382 13.8559Z" />
                </svg>
                <span className="d-flex d-lg-none d-xl-flex">
                  {t("Пользователи")}
                </span>
              </span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="reviews" disabled>
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M11.051 5.80725C11.4499 5.06425 12.5501 5.06425 12.9491 5.80725L14.4514 8.60535L17.6365 9.2112C18.4507 9.36605 18.7798 10.319 18.225 10.9151L15.9664 13.3415L16.3813 16.5888C16.4857 17.4065 15.6058 18.0073 14.8428 17.6394L12 16.2687L9.15727 17.6394C8.39427 18.0073 7.51432 17.4065 7.61877 16.5888L8.03362 13.3415L5.77507 10.9151C5.22022 10.319 5.54932 9.36605 6.36352 9.2112L9.54862 8.60535L11.051 5.80725Z" />
                  <path
                    opacity="0.32"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.5 12C1.5 17.799 6.20101 22.5 12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12ZM12.9491 5.80725C12.5501 5.06425 11.4499 5.06425 11.051 5.80725L9.54862 8.60535L6.36352 9.2112C5.54932 9.36605 5.22022 10.319 5.77507 10.9151L8.03362 13.3415L7.61877 16.5888C7.51432 17.4065 8.39427 18.0073 9.15727 17.6394L12 16.2687L14.8428 17.6394C15.6058 18.0073 16.4857 17.4065 16.3813 16.5888L15.9664 13.3415L18.225 10.9151C18.7798 10.319 18.4507 9.36605 17.6365 9.2112L14.4514 8.60535L12.9491 5.80725Z"
                  />
                </svg>
                <span>Отзывы</span>
              </span>
              <Badge className="ms-2" bg="success">
                Скоро
              </Badge>
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="dialogs"
              onClick={(e) => {
                e.preventDefault();
                close && close();
                navigate("/dialogs");
              }}
            >
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.12319 2.24369C5.3177 2.12083 7.30475 2 10.5 2C13.6952 2 15.6823 2.12083 16.8768 2.24369C17.8972 2.34863 18.6398 3.10549 18.7572 4.12444C18.8797 5.18775 19 6.7933 19 9C19 11.2067 18.8797 12.8123 18.7572 13.8756C18.6398 14.8945 17.8973 15.6514 16.877 15.7563C15.822 15.8648 14.1489 15.9717 11.571 15.9952C11.1714 15.9989 10.7875 16.1592 10.507 16.4437L8.816 18.1584C8.08185 18.9029 6.8125 18.4707 6.6853 17.4328L6.55145 16.3414C6.52175 16.099 6.3197 15.9137 6.0759 15.9011C5.26545 15.859 4.62502 15.8079 4.12397 15.7564C3.10365 15.6515 2.36022 14.8945 2.24278 13.8756C2.12023 12.8123 2 11.2067 2 9C2 6.7933 2.12023 5.18775 2.24278 4.12444C2.36022 3.10549 3.10287 2.34863 4.12319 2.24369ZM7 6C6.4477 6 6 6.4477 6 7C6 7.5523 6.4477 8 7 8H14C14.5523 8 15 7.5523 15 7C15 6.4477 14.5523 6 14 6H7ZM7 10.5C6.4477 10.5 6 10.9477 6 11.5C6 12.0523 6.4477 12.5 7 12.5H11C11.5523 12.5 12 12.0523 12 11.5C12 10.9477 11.5523 10.5 11 10.5H7Z"
                  />
                  <path d="M5.99902 7C5.99902 6.4477 6.44672 6 6.99902 6H13.999C14.5513 6 14.999 6.4477 14.999 7C14.999 7.5523 14.5513 8 13.999 8H6.99902C6.44672 8 5.99902 7.5523 5.99902 7Z" />
                  <path d="M5.99902 11.5C5.99902 10.9477 6.44672 10.5 6.99902 10.5H10.999C11.5513 10.5 11.999 10.9477 11.999 11.5C11.999 12.0523 11.5513 12.5 10.999 12.5H6.99902C6.44672 12.5 5.99902 12.0523 5.99902 11.5Z" />
                  <path d="M10.0257 16.9308L10.506 16.4437C10.7866 16.1592 11.1705 15.9989 11.57 15.9952C14.1479 15.9717 15.821 15.8648 16.876 15.7563C17.8963 15.6514 18.6388 14.8945 18.7562 13.8755C18.8363 13.1809 18.9154 12.2547 18.9606 11.0808C19.4674 11.1109 19.8664 11.1462 20.1773 11.1811C20.8028 11.2515 21.2614 11.7033 21.3392 12.3279C21.4206 12.9808 21.499 13.9995 21.499 15.5C21.499 17.0005 21.4206 18.0192 21.3392 18.6721C21.2614 19.2967 20.8021 19.7486 20.1766 19.8189C19.7585 19.8659 19.1808 19.9136 18.3962 19.9483C18.1641 19.9585 17.9673 20.1252 17.9223 20.3531L17.7516 21.219C17.6396 21.7868 16.9538 22.0192 16.5197 21.6364L15.0712 20.3589C14.8029 20.1223 14.4575 19.9901 14.0999 19.9816C12.7849 19.9504 11.9009 19.884 11.3222 19.819C10.6967 19.7488 10.2366 19.2967 10.1588 18.6721C10.1066 18.2528 10.0555 17.6826 10.0257 16.9308Z" />
                </svg>
                <span className="d-flex d-lg-none d-xl-flex">{t("Чат")}</span>
              </span>
              {notification?.message > 0 && (
                <Badge className="ms-2 d-flex d-lg-none d-xl-flex" bg="danger">
                  {notification.message}
                </Badge>
              )}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="notifications"
              onClick={(e) => {
                e.preventDefault();
                close && close();
                navigate("/notifications");
              }}
            >
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M4 9.95495V5C4 3.34315 5.34315 2 7 2H17C18.6568 2 20 3.34315 20 5V9.95495C20 10.6097 19.6819 11.2218 19.1343 11.5806C17.4417 12.6896 13.71 15 12 15C10.29 15 6.55835 12.6896 4.86571 11.5806C4.31808 11.2218 4 10.6097 4 9.95495Z"
                  />
                  <path d="M3.99902 6.251C3.48952 6.62919 3.03925 6.97507 2.67796 7.2576C2.07597 7.72835 1.69861 8.4226 1.64768 9.1851C1.57839 10.2227 1.49902 11.9645 1.49902 14.5C1.49902 16.8978 1.64098 18.5858 1.77362 19.6396C1.88298 20.5085 2.46471 21.1975 3.32241 21.3746C4.69765 21.6585 7.32372 22 11.999 22C16.6743 22 19.3004 21.6585 20.6756 21.3746C21.5333 21.1975 22.1151 20.5085 22.2244 19.6396C22.3571 18.5858 22.499 16.8978 22.499 14.5C22.499 11.9645 22.4197 10.2227 22.3504 9.1851C22.2994 8.4226 21.9221 7.72835 21.3201 7.2576C20.9588 6.97507 20.5085 6.62919 19.999 6.251V9.95495C19.999 10.6097 19.6809 11.2218 19.1333 11.5806C17.4407 12.6896 13.709 15 11.999 15C10.289 15 6.55737 12.6896 4.86473 11.5806C4.3171 11.2218 3.99902 10.6097 3.99902 9.95495V6.251Z" />
                  <path d="M6.99902 5.75C6.99902 5.3358 7.33482 5 7.74902 5H12.249C12.6632 5 12.999 5.3358 12.999 5.75C12.999 6.1642 12.6632 6.5 12.249 6.5H7.74902C7.33482 6.5 6.99902 6.1642 6.99902 5.75Z" />
                  <path d="M6.99902 9.25C6.99902 8.8358 7.33482 8.5 7.74902 8.5H15.249C15.6632 8.5 15.999 8.8358 15.999 9.25C15.999 9.6642 15.6632 10 15.249 10H7.74902C7.33482 10 6.99902 9.6642 6.99902 9.25Z" />
                </svg>
                <span className="d-flex d-lg-none d-xl-flex">
                  {t("Рассылки")}
                </span>
              </span>
              <Badge className="ms-2 d-flex d-lg-none d-xl-flex" bg="danger">
                {t("Новое")}
              </Badge>
              {/* <Badge className="ms-2" bg="success">
                Скоро
              </Badge> */}
            </NavLink>
          </li>
          <li className="mb-4">
            <NavLink
              to="statistics"
              disabled
              onClick={(e) => {
                e.preventDefault();
                close && close();
                navigate("/statistics");
              }}
            >
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20.9603 6.0506C20.9106 7.03855 19.9709 7.40975 19.242 6.741C18.9106 6.4369 18.5138 6.06255 18.0418 5.60285C16.9756 6.5731 15.322 8.08585 13.3506 9.91645C12.8329 10.3972 11.992 10.3435 11.5397 9.8007L11.5394 9.8003C10.6518 8.73835 9.75755 7.6807 8.8322 6.6514C7.7509 7.4453 5.81 8.95825 3.88389 10.8844C3.39573 11.3725 2.60427 11.3725 2.11612 10.8844C1.62796 10.3962 1.62796 9.60475 2.11612 9.1166C4.00573 7.227 6.079 5.4545 8.30255 3.96314L8.306 3.96082C8.30685 3.96027 8.3066 3.96042 8.3066 3.96042C8.8024 3.6299 9.46255 3.69527 9.8839 4.1166C10.8519 5.08455 11.7265 6.14925 12.6118 7.19265C14.1169 5.80065 15.3848 4.64087 16.274 3.8314C15.8705 3.41506 15.5362 3.06006 15.26 2.75898C14.591 2.02997 14.9624 1.08998 15.9506 1.04025C17.2115 0.976795 18.5055 0.939456 19.7511 1.17232C20.3119 1.27718 20.7233 1.68863 20.8281 2.24948C21.061 3.49521 21.0238 4.78949 20.9603 6.0506Z" />
                  <g opacity="0.32">
                    <path d="M2.60946 22.9843C1.77292 22.9631 1.13928 22.4599 1.07089 21.6259C1.02993 21.1264 1 20.4398 1 19.5C1 18.5602 1.02993 17.8736 1.07089 17.3741C1.13928 16.5401 1.77292 16.0369 2.60946 16.0157C2.97545 16.0064 3.43306 16 4 16C4.56694 16 5.02455 16.0064 5.39055 16.0157C6.2271 16.0369 6.8607 16.5401 6.9291 17.3741C6.97005 17.8736 7 18.5602 7 19.5C7 20.4398 6.97005 21.1264 6.9291 21.6259C6.8607 22.4599 6.2271 22.9631 5.39055 22.9843C5.02455 22.9936 4.56694 23 4 23C3.43306 23 2.97545 22.9936 2.60946 22.9843Z" />
                    <path d="M18.8455 22.9792C17.8709 22.9408 17.1875 22.2557 17.1243 21.2823C17.0588 20.2731 17 18.6337 17 16C17 13.3663 17.0588 11.727 17.1243 10.7177C17.1875 9.74435 17.8709 9.0592 18.8455 9.02075C19.1671 9.00805 19.549 9 20 9C20.451 9 20.8329 9.00805 21.1545 9.02075C22.1291 9.0592 22.8125 9.74435 22.8757 10.7177C22.9412 11.727 23 13.3663 23 16C23 18.6337 22.9412 20.2731 22.8757 21.2823C22.8125 22.2557 22.1291 22.9408 21.1545 22.9792C20.8329 22.992 20.451 23 20 23C19.549 23 19.1671 22.992 18.8455 22.9792Z" />
                    <path d="M10.7766 22.9832C9.8427 22.9548 9.162 22.3419 9.0949 21.41C9.0422 20.6775 9 19.5936 9 18C9 16.4064 9.0422 15.3225 9.0949 14.59C9.162 13.6581 9.8427 13.0453 10.7766 13.0169C11.1122 13.0067 11.5163 13 12 13C12.4837 13 12.8878 13.0067 13.2234 13.0169C14.1573 13.0453 14.838 13.6581 14.9051 14.59C14.9578 15.3225 15 16.4064 15 18C15 19.5936 14.9578 20.6775 14.9051 21.41C14.838 22.3419 14.1573 22.9548 13.2234 22.9832C12.8878 22.9934 12.4837 23 12 23C11.5163 23 11.1122 22.9934 10.7766 22.9832Z" />
                  </g>
                </svg>
                <span className="d-flex d-lg-none d-xl-flex">
                  {t("Статистика")}
                </span>
              </span>
              <Badge className="ms-2 d-flex d-lg-none d-xl-flex" bg="success">
                {t("Скоро")}
              </Badge>
            </NavLink>
          </li>
          <li>
            <p className="nav-label d-flex d-lg-none d-xl-flex">
              {t("Управление")}
            </p>
          </li>
          {/* <li>
            <NavLink to="upload/categories">
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.51374 21.6755C2.64245 21.49 2.06149 20.7886 1.96998 19.9025C1.8343 18.5887 1.68 16.1764 1.68 12C1.68 7.82354 1.8343 5.41129 1.96998 4.09746C2.06149 3.21136 2.64245 2.50997 3.51374 2.32448C4.889 2.03171 7.48149 1.67999 12 1.67999C16.5185 1.67999 19.111 2.03171 20.4862 2.32448C21.3576 2.50997 21.9385 3.21136 22.03 4.09746C22.1657 5.41129 22.32 7.82354 22.32 12C22.32 16.1764 22.1657 18.5887 22.03 19.9025C21.9385 20.7886 21.3576 21.49 20.4863 21.6755C19.111 21.9683 16.5185 22.32 12 22.32C7.48149 22.32 4.889 21.9683 3.51374 21.6755ZM13.0903 9.92639V5.20439C13.0895 4.95415 12.9899 4.71437 12.8132 4.53743C12.6365 4.36048 12.3971 4.26074 12.1472 4.25999H7.43203C7.18485 4.26442 6.94928 4.36587 6.77603 4.54249C6.60279 4.71912 6.50572 4.95681 6.50572 5.20439C6.50572 5.45198 6.60279 5.68966 6.77603 5.86629C6.94928 6.04292 7.18485 6.14436 7.43203 6.14879H9.88393C9.44126 6.57338 7.6716 8.33905 6.48607 9.52681C6.11757 9.896 6.11869 10.4933 6.48727 10.8624C6.85716 11.2328 7.45735 11.2325 7.82679 10.8616L11.2042 7.47095V9.92639C11.2086 10.1739 11.3099 10.4098 11.4863 10.5833C11.6627 10.7568 11.9 10.854 12.1472 10.854C12.3945 10.854 12.6318 10.7568 12.8082 10.5833C12.9845 10.4098 13.0858 10.1739 13.0903 9.92639ZM11.1983 18.7956V14.0736C11.2027 13.826 11.304 13.5901 11.4804 13.4166C11.6567 13.2431 11.8941 13.1459 12.1413 13.1459C12.3885 13.1459 12.6259 13.2431 12.8022 13.4166C12.9786 13.5901 13.0799 13.826 13.0843 14.0736V16.529L16.4617 13.1384C16.8312 12.7675 17.4314 12.7671 17.8013 13.1376C18.1698 13.5067 18.171 14.104 17.8025 14.4732C16.6169 15.6609 14.8473 17.4266 14.4046 17.8512H16.8565C17.1037 17.8556 17.3393 17.9571 17.5125 18.1337C17.6857 18.3103 17.7828 18.548 17.7828 18.7956C17.7828 19.0432 17.6857 19.2809 17.5125 19.4575C17.3393 19.6341 17.1037 19.7356 16.8565 19.74H12.1413C11.8914 19.7392 11.652 19.6395 11.4753 19.4626C11.2986 19.2856 11.199 19.0458 11.1983 18.7956Z"
                  />
                </svg>
                <span>Синхронизация</span>
              </span>
            </NavLink>
          </li> */}
          <li>
            <NavLink
              to="catalog/categories"
              onClick={(e) => {
                e.preventDefault();
                close && close();
                navigate("/catalog/categories");
              }}
            >
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    d="M15.2798 4.5H4.7202C3.77169 4.5 3 5.06057 3 5.75042C3 6.43943 3.77169 7 4.7202 7H15.2798C16.2283 7 17 6.43943 17 5.75042C17 5.06054 16.2283 4.5 15.2798 4.5Z"
                  />
                  <path d="M19.2798 10.75H8.7202C7.77169 10.75 7 11.3106 7 12.0004C7 12.6894 7.77169 13.25 8.7202 13.25H19.2798C20.2283 13.25 21 12.6894 21 12.0004C21 11.3105 20.2283 10.75 19.2798 10.75Z" />
                  <path d="M15.2798 17H4.7202C3.77169 17 3 17.5606 3 18.2504C3 18.9394 3.77169 19.5 4.7202 19.5H15.2798C16.2283 19.5 17 18.9394 17 18.2504C17 17.5606 16.2283 17 15.2798 17Z" />
                </svg>
                <span className="d-flex d-lg-none d-xl-flex">
                  {t("Каталог")}
                </span>
              </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="content/slides"
              onClick={(e) => {
                e.preventDefault();
                close && close();
                navigate("/content/slides");
              }}
            >
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="shape" opacity="0.32">
                    <path d="M21 8H13C12.7348 8 12.4804 7.89464 12.2929 7.70711C12.1054 7.51957 12 7.26522 12 7C12 6.73478 12.1054 6.48043 12.2929 6.29289C12.4804 6.10536 12.7348 6 13 6H21C21.2652 6 21.5196 6.10536 21.7071 6.29289C21.8946 6.48043 22 6.73478 22 7C22 7.26522 21.8946 7.51957 21.7071 7.70711C21.5196 7.89464 21.2652 8 21 8ZM21 12H13C12.7348 12 12.4804 11.8946 12.2929 11.7071C12.1054 11.5196 12 11.2652 12 11C12 10.7348 12.1054 10.4804 12.2929 10.2929C12.4804 10.1054 12.7348 10 13 10H21C21.2652 10 21.5196 10.1054 21.7071 10.2929C21.8946 10.4804 22 10.7348 22 11C22 11.2652 21.8946 11.5196 21.7071 11.7071C21.5196 11.8946 21.2652 12 21 12Z" />
                    <path d="M21 16H3C2.73478 16 2.48043 15.8946 2.29289 15.7071C2.10536 15.5196 2 15.2652 2 15C2 14.7348 2.10536 14.4804 2.29289 14.2929C2.48043 14.1054 2.73478 14 3 14H21C21.2652 14 21.5196 14.1054 21.7071 14.2929C21.8946 14.4804 22 14.7348 22 15C22 15.2652 21.8946 15.5196 21.7071 15.7071C21.5196 15.8946 21.2652 16 21 16ZM13 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19C2 18.7348 2.10536 18.4804 2.29289 18.2929C2.48043 18.1054 2.73478 18 3 18H13C13.2652 18 13.5196 18.1054 13.7071 18.2929C13.8946 18.4804 14 18.7348 14 19C14 19.2652 13.8946 19.5196 13.7071 19.7071C13.5196 19.8946 13.2652 20 13 20Z" />
                  </g>
                  <rect id="shape_2" x="2" y="4" width="8" height="8" rx="2" />
                </svg>
                <span className="d-flex d-lg-none d-xl-flex">
                  {t("Контент")}
                </span>
              </span>
            </NavLink>
            <NavLink
              to="sales/all"
              onClick={(e) => {
                e.preventDefault();
                close && close();
                navigate("/sales/all");
              }}
            >
              <span className="d-flex flex-row align-items-center">
                <svg
                  width="24"
                  height="24"
                  className="icon"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M11.4999 22C10.6924 22 9.88598 21.6934 9.26959 21.0812L8.50303 20.3146C8.20586 20.0185 7.80789 19.8547 7.38576 19.8537H6.30734C4.56422 19.8537 3.14557 18.435 3.14557 16.6919V15.6124C3.14452 15.1913 2.98071 14.7934 2.68354 14.4941L1.92959 13.7412C0.694702 12.5137 0.689452 10.507 1.91804 9.27103L2.68459 8.50343C2.98071 8.20626 3.14452 7.80828 3.14557 7.38615V6.30878C3.14557 4.56461 4.56422 3.14596 6.30734 3.14596H7.38681C7.80789 3.14596 8.20481 2.98215 8.50408 2.68288L9.25909 1.92998C10.4866 0.695098 12.4923 0.688797 13.7292 1.91843L14.4958 2.68498C14.794 2.98215 15.1909 3.14596 15.612 3.14596H16.6915C18.4346 3.14596 19.8533 4.56461 19.8533 6.30878V7.3872C19.8543 7.80828 20.0181 8.20626 20.3153 8.50553L21.0692 9.25948C21.6667 9.85382 21.9975 10.6456 22.0007 11.4909C22.0028 12.3309 21.6793 13.1216 21.0902 13.7191C21.0797 13.7296 21.0703 13.7412 21.0598 13.7506L20.3142 14.4962C20.0181 14.7934 19.8543 15.1913 19.8533 15.6135V16.6919C19.8533 18.435 18.4346 19.8537 16.6915 19.8537H15.612C15.1909 19.8547 14.793 20.0185 14.4947 20.3157L13.7397 21.0696C13.1244 21.6892 12.3116 22 11.4999 22"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.44568 9.45445C9.27242 9.62772 9.04771 9.72117 8.79884 9.72117C8.56572 9.72117 8.33681 9.62562 8.15305 9.4534C7.97873 9.28014 7.88003 9.04283 7.88003 8.80236C7.88003 8.57344 7.97663 8.33823 8.14675 8.15657C8.2402 8.06206 8.35151 7.99065 8.46282 7.95285C8.77994 7.80794 9.20102 7.893 9.45093 8.15552C9.54019 8.24477 9.60845 8.34243 9.6536 8.44429C9.70295 8.55349 9.72815 8.6774 9.72815 8.80236C9.72815 9.05228 9.6284 9.28434 9.44568 9.45445ZM14.8511 8.15124C14.4931 7.79421 13.9103 7.79421 13.5522 8.15124L8.15483 13.5486C7.79676 13.9067 7.79676 14.4895 8.15483 14.8486C8.32914 15.0219 8.55911 15.1174 8.80483 15.1174C9.05054 15.1174 9.28051 15.0219 9.45377 14.8486L14.8511 9.45122C15.2092 9.0921 15.2092 8.51036 14.8511 8.15124ZM14.5522 13.3534C14.212 13.2106 13.8087 13.2883 13.5399 13.5571C13.4843 13.6222 13.4055 13.723 13.352 13.8416C13.2952 13.9698 13.2879 14.1073 13.2879 14.1997C13.2879 14.2921 13.2952 14.4286 13.352 14.5567C13.4045 14.6744 13.4675 14.7699 13.5504 14.8529C13.7405 15.0293 13.961 15.1185 14.2067 15.1185C14.4398 15.1185 14.6687 15.024 14.8567 14.8487C15.0237 14.6817 15.115 14.4507 15.115 14.1997C15.115 13.9477 15.0237 13.7177 14.8557 13.5497C14.7632 13.4584 14.6519 13.387 14.5522 13.3534Z"
                  />
                </svg>
                <span className="d-flex d-lg-none d-xl-flex">{t("Акции")}</span>
              </span>
            </NavLink>
          </li>
          <li className="mb-4">
            <NavLink
              to="options/brands"
              onClick={(e) => {
                e.preventDefault();
                close && close();
                navigate("/options/brands");
              }}
            >
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.5565 2.10053C3.94224 2.2122 2 3.88336 2 6.5C2 9.11665 3.94224 10.7878 6.5565 10.8995C7.9246 10.9579 9.7062 11 12 11C14.2938 11 16.0754 10.9579 17.4435 10.8995C20.0577 10.7878 22 9.11665 22 6.5C22 3.88336 20.0577 2.2122 17.4435 2.10053C16.0754 2.04209 14.2938 2 12 2C9.7062 2 7.9246 2.04209 6.5565 2.10053ZM20 6.5C20 7.88071 18.8807 9 17.5 9C16.1193 9 15 7.88071 15 6.5C15 5.11929 16.1193 4 17.5 4C18.8807 4 20 5.11929 20 6.5Z"
                  />
                  <path
                    opacity="0.32"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.4435 13.1005C20.0577 13.2122 22 14.8833 22 17.5C22 20.1167 20.0577 21.7878 17.4435 21.8995C16.0754 21.9579 14.2938 22 12 22C9.7062 22 7.9246 21.9579 6.5565 21.8995C3.94224 21.7878 2 20.1167 2 17.5C2 14.8833 3.94224 13.2122 6.5565 13.1005C7.9246 13.0421 9.7062 13 12 13C14.2938 13 16.0754 13.0421 17.4435 13.1005ZM4 17.5C4 18.8807 5.11929 20 6.5 20C7.88071 20 9 18.8807 9 17.5C9 16.1193 7.88071 15 6.5 15C5.11929 15 4 16.1193 4 17.5Z"
                  />
                  <path
                    opacity="0.32"
                    d="M17.5 9C18.8807 9 20 7.88071 20 6.5C20 5.11929 18.8807 4 17.5 4C16.1193 4 15 5.11929 15 6.5C15 7.88071 16.1193 9 17.5 9Z"
                  />
                  <path d="M6.5 20C5.11929 20 4 18.8807 4 17.5C4 16.1193 5.11929 15 6.5 15C7.88071 15 9 16.1193 9 17.5C9 18.8807 7.88071 20 6.5 20Z" />
                </svg>
                <span className="d-flex d-lg-none d-xl-flex">
                  {t("Настройки")}
                </span>
              </span>
            </NavLink>
          </li>
          <li>
            <p className="nav-label d-flex d-lg-none d-xl-flex">
              {t("Возможности")}
            </p>
          </li>
          <li>
            <NavLink
              to="modules"
              onClick={(e) => {
                e.preventDefault();
                close && close();
                navigate("/modules");
              }}
            >
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.32"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.77688 21.3755C2.93261 21.1957 2.36966 20.5161 2.28099 19.6575C2.14952 18.3844 2 16.0469 2 12C2 7.95305 2.14952 5.6156 2.28099 4.34251C2.36966 3.48389 2.93261 2.80424 3.77688 2.62451C5.1095 2.34081 7.6216 2 12 2C16.3784 2 18.8905 2.34081 20.2231 2.62451C21.0674 2.80424 21.6304 3.48389 21.719 4.34251C21.8505 5.6156 22 7.95305 22 12C22 16.0469 21.8505 18.3844 21.719 19.6575C21.6304 20.5161 21.0674 21.1957 20.2232 21.3755C18.8905 21.6592 16.3784 22 12 22C7.6216 22 5.1095 21.6592 3.77688 21.3755Z"
                    fill="#637381"
                  />
                  <rect
                    x="5"
                    y="5.24263"
                    width="6"
                    height="6"
                    rx="2"
                    fill="#637381"
                  />
                  <rect
                    x="12"
                    y="8.24265"
                    width="5.5"
                    height="5.5"
                    rx="2"
                    transform="rotate(-45 12 8.24265)"
                    fill="#637381"
                  />
                  <rect
                    x="5"
                    y="13.4853"
                    width="6"
                    height="6"
                    rx="2"
                    fill="#637381"
                  />
                  <rect
                    x="13"
                    y="13.4853"
                    width="6"
                    height="6"
                    rx="2"
                    fill="#637381"
                  />
                </svg>
                <span className="d-flex d-lg-none d-xl-flex">
                  {t("Маркет")}
                </span>
              </span>
            </NavLink>
          </li>
        </ul>
        {!optionsBrand && (
          <div className="d-flex d-lg-none d-xl-flex justify-content-center">
            <Footer />
          </div>
        )}
      </nav>
    </div>
  );
});

export default AdminMenu;
