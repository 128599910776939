const BASE_URL = "https://admin.yooapp.ru";
const WEBHOOK_URL = "https://webhook.yooapp.ru";
const IO_URL = "https://io.yooapp.ru";
const FILE_URL = BASE_URL + "/file";

const apiRoutes = {
  // Auth
  AUTH_LOGIN: BASE_URL + "/auth/login",
  AUTH_CHECK: BASE_URL + "/auth/check",
  AUTH_REFRESH: BASE_URL + "/auth/refresh",
  AUTH_LOGOUT: BASE_URL + "/auth/logout",

  // Message
  MESSAGES: BASE_URL + "/message/",
  MESSAGE: BASE_URL + "/message/one",
  MESSAGES_VIEW: BASE_URL + "/message/view",

  // Order
  ORDERS_GET: BASE_URL + "/order",
  ORDER_GET: BASE_URL + "/order/one",
  ORDER_CREATE: BASE_URL + "/order/create",
  ORDER_EDIT: BASE_URL + "/order/edit",
  ORDER_NOTE_CREATE: BASE_URL + "/order/createNote",
  ORDER_NOTES_GET: BASE_URL + "/order/notes",
  ORDER_EDIT_STATUS: BASE_URL + "/order/editStatus",
  ORDER_DELETE: BASE_URL + "/order/delete",
  ORDER_LOG_UPDATE: BASE_URL + "/order/log",

  // Category
  CATEGORIES: BASE_URL + "/category",
  CATEGORY_EDIT: BASE_URL + "/category/edit",
  CATEGORIES_DELETE_ALL: BASE_URL + "/category/deleteAll",
  CATEGORY: BASE_URL + "/category/one",
  CATEGORY_CREATE: BASE_URL + "/category/create",
  CATEGORY_ADD_PARAM: BASE_URL + "/category/addParam",
  CATEGORY_DELETE_PARAM: BASE_URL + "/category/deleteParam",

  // Param
  PARAMS: BASE_URL + "/param",
  PARAM_EDIT: BASE_URL + "/param/edit",
  PARAMS_DELETE_ALL: BASE_URL + "/param/deleteAll",
  PARAM: BASE_URL + "/param/one",
  PARAM_CREATE: BASE_URL + "/param/create",
  PARAM_CHILD_CREATE: BASE_URL + "/param/createChild",

  // Message
  MESSAGES: BASE_URL + "/message",
  MESSAGE: BASE_URL + "/message/one",
  DIALOGS: BASE_URL + "/message/dialogs",

  // Member
  MEMBERS_GET: BASE_URL + "/member",
  MEMBER_GET: BASE_URL + "/member/one",
  MEMBER_CREATE: BASE_URL + "/member/create",
  MEMBER_LANG: BASE_URL + "/member/lang",

  // Invoice
  INVOICES: BASE_URL + "/invoice",
  INVOICE: BASE_URL + "/invoice/one",

  // Product
  PRODUCTS: BASE_URL + "/product",
  PRODUCT: BASE_URL + "/product/one",
  PRODUCT_LIST: BASE_URL + "/product/list",
  PRODUCT_DOWNLOAD: BASE_URL + "/product/download",
  PRODUCT_COPY: BASE_URL + "/product/copy",
  PRODUCT_CREATE: BASE_URL + "/product/create",
  PRODUCTS_DELETE_ALL: BASE_URL + "/product/deleteAll",
  PRODUCT_ADD_PARAM: BASE_URL + "/product/addParam",
  PRODUCT_DELETE_PARAM: BASE_URL + "/product/deleteParam",

  // Sale
  SALES: BASE_URL + "/sale",
  SALE: BASE_URL + "/sale/one",
  SALE_CREATE: BASE_URL + "/sale/create",

  //Languages
  LANGUAGES: BASE_URL + "/language",
  LANGUAGE: BASE_URL + "/language/one",

  // Promo
  PROMOS: BASE_URL + "/promo",
  PROMO: BASE_URL + "/promo/one",
  PROMO_CHECK: BASE_URL + "/promo/check",

  // Banner
  BANNERS: BASE_URL + "/slide",
  BANNER: BASE_URL + "/slide/one",
  BANNER_CREATE: BASE_URL + "/slide/create",

  // Blog
  BLOGS: BASE_URL + "/blog",
  BLOG: BASE_URL + "/blog/one",
  BLOG_CREATE: BASE_URL + "/blog/create",
  BLOG_EDIT: BASE_URL + "/blog/edit",

  // Portfolio
  PORTFOLIO: BASE_URL + "/portfolio",
  PORTFOLIO_ONE: BASE_URL + "/portfolio/one",
  PORTFOLIO_EDIT: BASE_URL + "/portfolio/edit",

  // Addition
  ADDITIONS: BASE_URL + "/addition",
  ADDITION: BASE_URL + "/addition/one",
  ADDITION_CREATE: BASE_URL + "/addition/create",

  // Zones
  ZONES: BASE_URL + "/zone",
  ZONES_UPLOAD: BASE_URL + "/zone/upload",
  ZONE: BASE_URL + "/zone/one",

  // Story
  STORIES: BASE_URL + "/story",
  STORY: BASE_URL + "/story/one",
  STORY_CREATE: BASE_URL + "/story/create",
  STORY_EDIT: BASE_URL + "/story/edit",

  // Statuses
  STATUSES: BASE_URL + "/status",
  STATUS: BASE_URL + "/status/one",

  // Modules
  MODULES: BASE_URL + "/module",
  MODULE_EDIT: BASE_URL + "/module/edit",
  MODULE: BASE_URL + "/module/one",
  MODULE_WEB: BASE_URL + "/module/web",
  MODULE_APP: BASE_URL + "/module/app",
  MODULE_BRAND: BASE_URL + "/module/brand",
  MODULE_BRAND_OPTIONS: BASE_URL + "/module/brand/options",
  MODULE_DOMAIN_UPDATE: BASE_URL + "/module/updateDomain",
  MODULE_RESET: BASE_URL + "/module/reset",
  MODULE_MARKETPLACE: BASE_URL + "/module/marketplace",

  PAYMENT: BASE_URL + "/module/payment",

  // Storage
  STORAGES: BASE_URL + "/storage",
  STORAGE: BASE_URL + "/storage/one",
  STORAGE_CREATE: BASE_URL + "/storage/create",

  // Ingredient
  INGREDIENTS: BASE_URL + "/ingredient",
  INGREDIENT: BASE_URL + "/ingredient/one",
  INGREDIENT_CREATE: BASE_URL + "/ingredient/create",

  // Modifier
  MODIFIERS: BASE_URL + "/modifier",
  MODIFIER_CREATE: BASE_URL + "/modifier/create",
  MODIFIER: BASE_URL + "/modifier/one",

  // Wish
  WISHES: BASE_URL + "/wish",
  WISH: BASE_URL + "/wish/one",

  // Wish
  NOTIFICATIONS: BASE_URL + "/notification",
  NOTIFICATION: BASE_URL + "/notification/one",

  // Affiliate
  CITIES: BASE_URL + "/city",
  CITY: BASE_URL + "/city/one",

  //Tables
  TABLES: BASE_URL + "/table",
  TABLE: BASE_URL + "/table/one",

  // Affiliate
  AFFILIATE: BASE_URL + "/affiliate",
  AFFILIATE_ONE: BASE_URL + "/affiliate/one",
  AFFILIATE_CREATE: BASE_URL + "/affiliate/create",
  AFFILIATE_MAIN: BASE_URL + "/affiliate/main",

  // Brand
  BRANDS: BASE_URL + "/brand",
  BRAND: BASE_URL + "/brand/one",
  BRAND_CREATE: BASE_URL + "/brand/create",
  BRAND_MAIN: BASE_URL + "/brand/main",

  // Document
  DOCUMENTS: BASE_URL + "/document",
  DOCUMENT: BASE_URL + "/document/one",
  DOCUMENT_VIEW: BASE_URL + "/document/view",
  DOCUMENT_CREATE: BASE_URL + "/document/create",

  REDIRECT_PAY: BASE_URL + "/redirect",

  // Statistic
  STATISTIC: BASE_URL + "/statistic",

  // User
  USER: BASE_URL + "/user",
  USER_ONE: BASE_URL + "/user/one",
  USER_CREATE_POINT: BASE_URL + "/user/point",
  USER_UPDATE_POINT: BASE_URL + "/user/point",
  USER_BLOCKED: BASE_URL + "/user/blocked",

  // Options
  OPTIONS: BASE_URL + "/option",

  //Terminal
  TERMINALS: BASE_URL + "/terminal",
  TERMINAL: BASE_URL + "/terminal/one",

  // Sync
  EPR_CATEGORY: BASE_URL + "/sync/category",
  EPR_PRODUCT: BASE_URL + "/sync/product",
  EPR_MODIFIER: BASE_URL + "/sync/modifier",
  EPR_TABLE: BASE_URL + "/sync/table",
  EPR_ORGANIZATION: BASE_URL + "/sync/organization",
  EPR_DISCOUNT: BASE_URL + "/sync/discount",
  EPR_COUPONS: BASE_URL + "/sync/coupon",
  EPR_TEPES_DELIVERY: BASE_URL + "/sync/delivery",
  EPR_TYPES_PAYMENT: BASE_URL + "/sync/payment",
  EPR_TERMINAL: BASE_URL + "/sync/terminal",
  EPR_ALL: BASE_URL + "/sync/all",
  EPR_MENU: BASE_URL + "/sync/menu",
  EPR_PRICE: BASE_URL + "/sync/price",
  EPR_PARAM: BASE_URL + "/sync/param",

  // Uplaods
  UPLOAD_CATEGORIES: "/upload/categories",
  UPLOAD_CATEGORY: "/upload/category",
  UPLOAD_PRODUCTS: "/upload/products",
  UPLOAD_PRODUCT: "/upload/product",
  UPLOAD_MODIFIERS: "/upload/modifiers",
  UPLOAD_MODIFIER: "/upload/modifier",

  ADMIN_SALES_GET: "/getSales",
  ADMIN_SALE_GET: "/getSale",
  ADMIN_SALE_CREATE: "/createSale",
  ADMIN_SALE_EDIT: "/editSale",
  ADMIN_SALE_DELETE: "/deleteSale",

  ADMIN_MARKS_GET: "/getMarks",
  ADMIN_MARK_GET: "/getMark",
  ADMIN_MARK_CREATE: "/createMark",
  ADMIN_MARK_EDIT: "/editMark",
  ADMIN_MARK_DELETE: "/deleteMark",

  ADMIN_STATISTIC_GET: "/statistic",

  ADMIN_SITE_GET: "/getOptions",
};

const apiResponseMessages = {
  // Admin
  ADMIN_CATEGORY_CREATE: "Категория успешно создана",
  ADMIN_CATEGORY_EDIT: "Категория успешно изменена",
  ADMIN_CATEGORY_DELETE: "Категория успешно удалена",
  ADMIN_OPTIONS_EDIT: "Настройки успешно сохранены",
  ADMIN_PRODUCT_CREATE: "Товар успешно создана",
  ADMIN_PRODUCT_EDIT: "Товар успешно изменен",
  ADMIN_PRODUCT_DELETE: "Товар успешно удалена",

  ADMIN_ORDER_EDIT: "Заказ успешно изменен",
  ADMIN_ORDER_DELETE: "Заказ успешно удален",

  ADMIN_SALE_CREATE: "Акция успешно создана",
  ADMIN_SALE_EDIT: "Акция успешно изменена",
  ADMIN_SALE_DELETE: "Акция успешно удалена",

  ADMIN_MARK_CREATE: "Метка успешно создана",
  ADMIN_MARK_EDIT: "Метка успешно изменена",
  ADMIN_MARK_DELETE: "Метка успешно удалена",

  ADMIN_USER_EDIT: "Клиент успешно изменен",
  ADMIN_USER_DELETE: "Клиент успешно удален",

  ADMIN_NOTIFICATION_CREATE: "Уведомление успешно отправлено",
  ADMIN_NOTIFICATION_DELETE: "Уведомление успешно удалено",
};

const apiRejectMessages = {
  DEFAULT: "Что-то пошло не так, повторите попытку позже",
  INVALID_KEY: "Неверный ключ подтверждения",
  USER_EXISTS: "Пользователь с таким номером уже существует",
  USER_NOT_FOUND: "Такого пользователя не существует",
  USER_NOT_EXIST: "Такого пользователя не существует",
  CART_NOT_VALID_COUNT: "Значение не может быть меньше 1",
  PAGE_ERROR:
    "Не удалось загрузить страницу, вернитесь назад или перезагрузите страницу",
};

const apiErrors = {
  INVALID_KEY: "INVALID_KEY",
  USER_EXISTS: "USER_EXISTS",
  USER_NOT_FOUND: "USER_NOT_FOUND",
  USER_NOT_EXIST: "USER_NOT_EXIST",
};

export {
  WEBHOOK_URL,
  BASE_URL,
  FILE_URL,
  IO_URL,
  apiRoutes,
  apiResponseMessages,
  apiRejectMessages,
  apiErrors,
};
