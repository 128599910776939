import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import { Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import { kkal, weightFoodTypes, weightShopTypes } from "../../helpers/product";
import { editModifier, getModifier } from "../../services/modifier";
import { useSelector } from "react-redux";
import { localeData } from "../../helpers/all";
import DragDropFile from "../../components/DragDropFile";
import PreviewImages from "../../components/PreviewImages";

const ModifierEdit = () => {
  const { modifierId } = useParams();
  const brand = useSelector((state) => state?.brand?.active);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const {
    control,
    register,
    formState: { errors },
    reset,
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      type: "modifier",
      options: {
        typeSend: "modifier",
      },
    },
  });

  const data = useWatch({ control });

  const kkalData = useRef();

  useLayoutEffect(() => {
    getModifier(modifierId)
      .then((res) => {
        if (res) {
          reset(res);
          if (res?.modifier?.storages) {
            kkalData.current = kkal(res.modifier.storages);
          }
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  }, []);

  const onSubmit = useCallback((data) => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.file) {
      for (let file of data?.file) {
        formData.append("file", file);
      }
    }

    editModifier(formData)
      .then(() => {
        NotificationManager.success("Модификатор успешно обновлен");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  if (loading) {
    return <Loader full />;
  }

  if (!data) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такого модификатора нет</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title="Редактировать модификатор" />
      <div>
        <Link
          to="/catalog/modifiers"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <h3 className="mb-4">Редактировать модификатор</h3>
      <Row>
        <Col md={8}>
          <Card>
            {data.options?.titleOld &&
              data.options?.titleOld != data?.title && (
                <div className="d-block fs-08 px-4 pt-4">
                  <span className="text-muted">Старое название:</span>{" "}
                  <span className="text-success">{data.options.titleOld}</span>
                </div>
              )}
            <Card.Body>
              <Row>
                <Col md={8}>
                  <div className="mb-3">
                    <Input
                      label="Название"
                      name="title"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Обязательное поле",
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Input
                      label="Артикул"
                      name="code"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      defaultValue={0}
                      label="Порядок"
                      name="priority"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Select
                      classNameContainer="w-100"
                      label="Тип"
                      data={[
                        { title: "Модификатор", value: "modifier" },
                        { title: "Добавка", value: "addition" },
                        { title: "Пожелание", value: "wish" },
                      ]}
                      value={data.type ?? null}
                      onClick={(e) => {
                        setValue("type", e.value);
                      }}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Select
                      classNameContainer="w-100"
                      label="Тип отправки"
                      data={[
                        { title: "Модификатор", value: "modifier" },
                        { title: "Добавка", value: "addition" },
                        { title: "Товар", value: "product" },
                      ]}
                      value={data.options?.typeSend ?? "modifier"}
                      onClick={(e) => {
                        setValue("options.typeSend", e.value);
                      }}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Textarea
                      defaultValue={data.description}
                      label="Описание"
                      name="description"
                      errors={errors}
                      rows={6}
                      register={register}
                    />
                  </div>
                </Col>
                {/* <Col lg={6}>
                  <div className="mb-3">
                    <Select
                      label="Категория"
                      search
                      value={data.categoryId}
                      data={categories.items.map((e) => ({
                        title: e.title,
                        value: e.id,
                      }))}
                      onClick={(e) => setValue("categoryId", e.value)}
                    />
                  </div>
                </Col> */}
                {data.apiId && (
                  <Col md={12}>
                    <Input
                      defaultValue={data.apiId}
                      label="API ID"
                      name="apiId"
                      readOnly={false}
                    />
                  </Col>
                )}
                <Col md={12}>
                  <Form.Check className="mb-3 d-inline-block">
                    <Form.Check.Input
                      type="checkbox"
                      id="optional"
                      defaultChecked={!!data?.options?.optional}
                      {...register("options.optional")}
                    />
                    <Form.Check.Label htmlFor="optional" className="ms-2">
                      Необязательный модификатор
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Row>
            </Card.Body>
            <Tabs className="mb-3" fill>
              <Tab.Pane eventKey={1} title="Филиалы" className="px-3">
                {data?.affiliates?.items?.length > 0 &&
                  data.affiliates.items.map((e) => {
                    let option =
                      data?.modifierOptions?.length > 0 &&
                      data.modifierOptions.find(
                        (item) => item.affiliateId === e.id
                      );
                    let optionIndex =
                      data?.modifierOptions?.length > 0 &&
                      data.modifierOptions.findIndex(
                        (item) => item.affiliateId === e.id
                      );

                    return (
                      <div className="d-flex justify-content-between align-items-center mb-3">
                        <div>
                          <Form.Check>
                            <Form.Check.Input
                              type="checkbox"
                              name="modifierOptions"
                              checked={option?.id}
                              onChange={() => {
                                if (option?.id) {
                                  let newArray = data.modifierOptions.filter(
                                    (item) => item.affiliateId != e.id
                                  );
                                  setValue("modifierOptions", newArray);
                                } else {
                                  let newArray =
                                    data?.modifierOptions?.length > 0
                                      ? [
                                          ...data.modifierOptions,
                                          {
                                            affiliateId: e.id,
                                            price: option?.price
                                              ? option.price
                                              : data.price,
                                          },
                                        ]
                                      : [
                                          {
                                            affiliateId: e.id,
                                            price: option?.price
                                              ? option.price
                                              : data.price,
                                          },
                                        ];
                                  setValue("modifierOptions", newArray);
                                }
                              }}
                            />
                            <Form.Check.Label className="ms-2 fs-09">
                              {e?.title ? e.title : e.full}
                            </Form.Check.Label>
                          </Form.Check>
                        </div>
                        <div className="d-flex align-items-center justify-content-end">
                          <Input
                            label="Цена"
                            className="input-mini"
                            defaultValue={
                              option?.price ? option.price : data.price
                            }
                            readOnly={!!option?.affiliateId}
                            name={"optionPrice" + e.id}
                            min={0}
                            type="number"
                            onChange={(value) => {
                              if (
                                optionIndex != -1 &&
                                data?.modifierOptions?.length > 0
                              ) {
                                data.modifierOptions[optionIndex].price = value;
                                setValue(
                                  "modifierOptions",
                                  data.modifierOptions
                                );
                              }
                            }}
                          />
                          <Input
                            label="Скидка"
                            className="ms-2 input-mini"
                            defaultValue={
                              option?.price ? option.price : data.price
                            }
                            readOnly={!!option?.affiliateId}
                            name={"optionPrice" + e.id}
                            min={0}
                            type="number"
                            onChange={(value) => {
                              if (
                                optionIndex != -1 &&
                                data?.modifierOptions?.length > 0
                              ) {
                                data.modifierOptions[optionIndex].discount =
                                  value;
                                setValue(
                                  "modifierOptions",
                                  data.modifierOptions
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
              </Tab.Pane>
              {brand.options?.lang?.length > 1 && (
                <Tab.Pane eventKey={7} title="Перевод" className="px-3">
                  <div className="px-2">
                    {brand.options?.lang
                      .filter((e) => e != "ru")
                      .map((lang) => {
                        let langTitle = localeData.find(
                          (e) => e.lang === lang
                        )?.title;
                        return (
                          <>
                            <div className="mb-3 fw-7">{langTitle}</div>
                            <div className="mb-3">
                              <Input
                                className="mb-3"
                                label={`Название (${langTitle})`}
                                name={`options.${lang}.title`}
                                errors={errors}
                                register={register}
                              />
                              <Textarea
                                label={`Описание (${langTitle})`}
                                name={`options.${lang}.desc`}
                                errors={errors}
                                register={register}
                              />
                            </div>
                          </>
                        );
                      })}
                  </div>
                </Tab.Pane>
              )}
            </Tabs>
          </Card>
        </Col>
        <Col md={4} className="position-relative">
          <div className="position-sticky top-1">
            <Card className="mb-3" body>
              <DragDropFile
                col={3}
                maxAspect={false}
                file={data.file}
                main
                multiple
                onChange={(e) => setValue("file", e)}
                onDelete={(e) => setValue("file", e.file)}
              />
              <PreviewImages
                medias={data?.medias}
                type="modifier"
                col={6}
                main
                onMain={(e) => setValue("main", e)}
                onDelete={(e) => {
                  setValue("medias", e.medias);
                  let newArrayDelete =
                    data?.delete?.length > 0
                      ? [...data.delete, e.delete]
                      : [e.delete];
                  setValue("delete", newArrayDelete);
                }}
              />
            </Card>
            {brand?.type == "food" ? (
              <Card body className="mb-3">
                <p className="fs-08">
                  Энергетическая ценность в 100г -{" "}
                  <span className="text-success">
                    {kkalData?.current?.kkal ?? 0}
                  </span>{" "}
                  ккал
                </p>
                <Row className="gx-2">
                  <Col lg={4}>
                    <div className="mt-4">
                      <Input
                        value={kkalData?.current?.protein ?? 0}
                        label="Белки, г"
                        readOnly={false}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mt-4">
                      <Input
                        value={kkalData?.current?.fat ?? 0}
                        label="Жиры, г"
                        readOnly={false}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mt-4">
                      <Input
                        value={kkalData?.current?.carbohydrate ?? 0}
                        label="Углеводы, г"
                        readOnly={false}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="gx-2">
                  <Col lg={4}>
                    <div className="mt-4">
                      <Input
                        value={
                          kkalData?.current?.kkalAll ?? kkalData?.current?.kkal
                        }
                        label="Всего ккал, г"
                        readOnly={false}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mt-4">
                      <Input
                        defaultValue={
                          data.energy.weight ?? kkalData?.current?.weight ?? 0
                        }
                        label="Вес, г"
                        name="energy.weight"
                        min={0}
                        type="number"
                        errors={errors}
                        register={register}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mt-4">
                      <Select
                        label="Единица измерения"
                        title="г, кг, т ..."
                        value={data?.energy?.weightType}
                        data={
                          brand?.type == "food"
                            ? weightFoodTypes
                            : weightShopTypes
                        }
                        onClick={(e) => setValue("energy.weightType", e.value)}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            ) : (
              <Card body className="mb-3">
                <Row className="gx-2">
                  <Col lg={6}>
                    <div>
                      <Input
                        defaultValue={kkalData?.current?.weight ?? 0}
                        label={"Вес, " + (data?.energy?.weightType ?? "г")}
                        name="energy.weight"
                        min={0}
                        type="number"
                        errors={errors}
                        register={register}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <Select
                        label="Единица измерения"
                        title="г, кг, т ..."
                        value={data?.energy?.weightType ?? "г"}
                        data={
                          brand?.type == "food"
                            ? weightFoodTypes
                            : weightShopTypes
                        }
                        onClick={(e) => setValue("energy.weightType", e.value)}
                      />
                    </div>
                  </Col>
                </Row>
              </Card>
            )}
            <Card body className="mb-3">
              <p className="fs-08">Цена</p>
              <Row className="gx-2">
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={0}
                      label="Цена"
                      name="price"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={0}
                      label="Скидка"
                      name="discount"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <Button
              className="btn-primary align-self-end w-100 mt-3"
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ModifierEdit;
