import moment from "moment-timezone";

const generateKey = (length = 20, type) => {
  let chars =
    type == "number"
      ? "0123456789"
      : type == "letters"
        ? "abcdefghijklmnopqrstuvwxyz"
        : "0123456789abcdefghijklmnopqrstuvwxyz";
  let key = "";
  for (var i = 1; i <= length; i++) {
    var randomNumber = Math.floor(Math.random() * chars.length);
    key += chars.substring(randomNumber, randomNumber + 1);
  }
  return key;
};
const work = [
  {
    end: null,
    start: null,
    status: true,
  },
  {
    end: null,
    start: null,
    status: true,
  },
  {
    end: null,
    start: null,
    status: true,
  },
  {
    end: null,
    start: null,
    status: true,
  },
  {
    end: null,
    start: null,
    status: true,
  },
  {
    end: null,
    start: null,
    status: true,
  },
  {
    end: null,
    start: null,
    status: true,
  },
];
const workPromo = [
  {
    status: false,
  },
  {
    status: false,
  },
  {
    status: false,
  },
  {
    status: false,
  },
  {
    status: false,
  },
  {
    status: false,
  },
  {
    status: false,
  },
];
const tagsData = [
  { name: "chicken", value: "/images/tags/chicken.png" },
  { name: "fish", value: "/images/tags/fish.png" },
  { name: "halal", value: "/images/tags/halal.png" },
  { name: "meat", value: "/images/tags/meat.png" },
  { name: "pepper", value: "/images/tags/pepper.png" },
  { name: "vegetarian", value: "/images/tags/vegetarian.png" },
];
const isWork = (start, end, now) => {
  try {
    const timezone = moment.tz.guess();

    if (!now) {
      now = moment.tz();
    }
    if (!start || !end) {
      return false;
    }
    if (end === "00:00") {
      end = "23:59";
    }

    const startTime = moment.tz(start, "HH:mm", timezone).utc();
    const endTime = moment.tz(end, "HH:mm", timezone).utc();
    const isEndNextDay = endTime.isSameOrBefore(startTime);
    if (isEndNextDay) {
      endTime.add(1, "day");
    }
    const nowTime = moment.tz(now, "HH:mm", timezone).utc();

    return nowTime.isBetween(startTime, endTime, null, "()");
  } catch (err) {
    return false;
  }
};
const generateUrl = (str) => {
  if (str?.length > 0) {
    var url = str.replace(/[\s]+/gi, "-");
    url = translit(url);
    url = url.replace(/[^0-9a-z_]+/gi, "").toLowerCase();
    return url;
  }
};

const translit = (str) => {
  var ru =
    "А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я".split(
      "-"
    );
  var en =
    "A-a-B-b-V-v-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-N-n-O-o-P-p-R-r-S-s-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-'-'-Y-y-'-'-E-e-YU-yu-YA-ya".split(
      "-"
    );
  var res = "";
  for (var i = 0, l = str.length; i < l; i++) {
    var s = str.charAt(i),
      n = ru.indexOf(s);
    if (n >= 0) {
      res += en[n];
    } else {
      res += s;
    }
  }
  return res;
};

const currencyData = [
  {
    title: "Российский рубль",
    currency: "RUB",
    symbol: "₽"
  },
  {
    title: "Маврикийская рупия",
    currency: "MUR",
    symbol: "₹"
  },
  {
    title: "Казахский тенге",
    currency: "KZT",
    symbol: "₸"
  },
  {
    title: "Американский доллар",
    currency: "USD",
    symbol: "$"
  },
];

const getCurrencySymbol = (value) => {
  return currencyData.find(e => e.currency.toLowerCase() === value.toLowerCase())?.symbol ?? '₽'
}

const localeData = [
  {
    title: "Русский",
    image: require("../assets/images/country/russia.png"),
    lang: "ru",
  },
  {
    title: "Казахский",
    image: require("../assets/images/country/kazakhstan.png"),
    lang: "kk",
  },
  {
    title: "Английский",
    image: require("../assets/images/country/united-states.png"),
    lang: "en",
  },
];

const timezones = [
  {
    value: "Pacific/Midway",
    title: "Мидуэй (UTC-11)",
  },
  {
    value: "Pacific/Niue",
    title: "Ниуэ (UTC-11)",
  },
  {
    value: "Pacific/Pago_Pago",
    title: "Паго-Паго (UTC-11)",
  },
  {
    value: "Pacific/Honolulu",
    title: "Гонолулу (UTC-10)",
  },
  {
    value: "America/Adak",
    title: "Адак (UTC-9)",
  },
  {
    value: "Pacific/Marquesas",
    title: "Маркизские острова (UTC-9:30)",
  },
  {
    value: "America/Anchorage",
    title: "Анкоридж (UTC-8)",
  },
  {
    value: "America/Los_Angeles",
    title: "Лос-Анджелес (UTC-7)",
  },
  {
    value: "America/Denver",
    title: "Денвер (UTC-6)",
  },
  {
    value: "America/Chicago",
    title: "Чикаго (UTC-5)",
  },
  {
    value: "America/New_York",
    title: "Нью-Йорк (UTC-4)",
  },
  {
    value: "America/Halifax",
    title: "Галифакс (UTC-3)",
  },
  {
    value: "America/St_Johns",
    title: "Сент-Джонс (UTC-2:30)",
  },
  {
    value: "America/Noronha",
    title: "Норонья (UTC-2)",
  },
  {
    value: "Atlantic/Cape_Verde",
    title: "Кабо-Верде (UTC-1)",
  },
  {
    value: "Europe/London",
    title: "Лондон (UTC+0)",
  },
  {
    value: "Europe/Berlin",
    title: "Берлин (UTC+1)",
  },
  {
    value: "Europe/Kaliningrad",
    title: "Калининград (UTC+2)",
  },
  {
    value: "Europe/Moscow",
    title: "Москва (UTC+3)",
  },
  {
    value: "Europe/Samara",
    title: "Самара (UTC+4)",
  },
  {
    value: "Asia/Yekaterinburg",
    title: "Екатеринбург (UTC+5)",
  },
  {
    value: "Asia/Omsk",
    title: "Омск (UTC+6)",
  },
  {
    value: "Asia/Novosibirsk",
    title: "Новосибирск (UTC+7)",
  },
  {
    value: "Asia/Irkutsk",
    title: "Иркутск (UTC+8)",
  },
  {
    value: "Asia/Yakutsk",
    title: "Якутск (UTC+9)",
  },
  {
    value: "Asia/Vladivostok",
    title: "Владивосток (UTC+10)",
  },
  {
    value: "Asia/Magadan",
    title: "Магадан (UTC+11)",
  },
  {
    value: "Asia/Kamchatka",
    title: "Петропавловск-Камчатский (UTC+12)",
  },
  {
    value: "Pacific/Apia",
    title: "Апиа (UTC+13)",
  },
  {
    value: "Pacific/Kiritimati",
    title: "Киритимати (UTC+14)",
  },
];
const getLang = (value) => {
  let lang = localeData.find((e) => e.lang == value) ?? "ru";
  return lang;
};

const audioList = [
  { id: false, title: "Без звука", src: false },
  {
    id: 1,
    title: "Звук уведомления 1",
    src: require("../assets/audio/notification-1.mp3"),
  },
  {
    id: 2,
    title: "Звук уведомления 2",
    src: require("../assets/audio/notification-2.mp3"),
  },
  {
    id: 3,
    title: "Звук уведомления 3",
    src: require("../assets/audio/notification-3.mp3"),
  },
  {
    id: 4,
    title: "Звук уведомления 4",
    src: require("../assets/audio/notification-4.mp3"),
  },
];
const languageCode = (value) => {
  const normalizedLanguageCode = value.toLowerCase().replace(/_/g, "-");

  const mappedLanguageCode = {
    "ru": "ru",
    "ru-ru": "ru",
    "ru-RU": "ru",
    "ru_RU": "ru",
    "kk": "kk",
    "kk-kz": "kk",
    "kk-KZ": "kk",
    "kk_KZ": "kk",
    "en": "en",
    "en-us": "en",
    "en-US": "en",
    "en_US": "en",
  };

  return mappedLanguageCode[normalizedLanguageCode] || "ru";
};
export {
  generateKey,
  generateUrl,
  languageCode,
  isWork,
  getLang,
  audioList,
  timezones,
  localeData,
  work,
  workPromo,
  tagsData,
  currencyData,
  getCurrencySymbol
};
