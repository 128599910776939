import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  Badge,
  ButtonToolbar,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import {
  IoAlertCircleOutline,
  IoChevronBackOutline,
  IoRefreshOutline,
  IoTimeOutline,
} from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import { Status } from "../../components/StatusOrder";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import { customPrice } from "../../helpers/product";
import {
  createNote,
  editOrder,
  getOrder,
  updateLog,
} from "../../services/order";
import Meta from "../../components/Meta";
import Select from "../../components/UI/Select";
import DataTable from "../../components/DataTable";
import socket from "../../config/socket";
import { useSelector } from "react-redux";
import Chat from "../../components/chat";
import { useTranslation } from "react-i18next";

const OrderEdit = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const auth = useSelector((state) => state.auth);
  const affiliates = useSelector((state) => state.affiliate.data);

  const [edit, setEdit] = useState(false);
  const [notes, setNotes] = useState([]);
  const [loading, setLoading] = useState(true);

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  var log = "";

  try {
    log = JSON.parse(data.log);
  } catch (error) {
    log = data.log;
  }

  const statuses = {
    new: data?.statuses?.find((e) => e.status.value == "new") ?? false,
    reservation:
      data?.statuses?.find((e) => e.status.value == "reservation") ?? false,
    preparing:
      data?.statuses?.find((e) => e.status.value == "preparing") ?? false,
    prepared:
      data?.statuses?.find((e) => e.status.value == "prepared") ?? false,
    delivery:
      data?.statuses?.find((e) => e.status.value == "delivery") ?? false,
    done: data?.statuses?.find((e) => e.status.value == "done") ?? false,
    processing:
      data?.statuses?.find((e) => e.status.value == "processing") ?? false,
    canceled:
      data?.statuses?.find((e) => e.status.value == "canceled") ?? false,
  };

  useEffect(() => {
    socket.on("order/" + orderId, (data) => {
      data?.statuses?.length > 0 && setValue("statuses", data.statuses);
    });
    socket.on("note/" + orderId, (data) => {
      data && setNotes((items) => [data, ...items]);
    });
    return () => {
      socket.off("order/" + orderId);
      socket.off("note/" + orderId);
    };
  }, []);

  useEffect(() => {
    if (!statuses.reservation && !statuses.new) {
      setEdit(false);
    } else if (statuses.new && data.serving) {
      setValue("serving", "");
    }
  }, [statuses]);

  const getData = () => {
    getOrder({ orderId })
      .then((res) => {
        if (res) {
          res.serving = res.serving
            ? moment(res.serving).format("YYYY-MM-DDTHH:mm")
            : null;
          reset(res);
          setNotes(res.note);
          setLoading(false);
        }
      })
      .catch((error) => setLoading(false));
  };

  useLayoutEffect(() => {
    getData();
  }, []);

  const transactionColumns = [
    {
      name: "Дата",
      selector: "createdAt",
      cell: (row) => moment(row.createdAt).format("DD MMM YYYY kk:mm"),
    },
    {
      name: "id",
      selector: "paymentId",
    },
    {
      name: "Статус",
      selector: "status",
    },
  ];

  const onSubmit = useCallback(
    (data) => {
      editOrder(data)
        .then(() => {
          setEdit(!edit);
          NotificationManager.success("Заказ успешно обновлен");
        })
        .catch((error) => {
          NotificationManager.error(
            typeof error?.response?.data?.error == "string"
              ? error.response.data.error
              : "Неизвестная ошибка"
          );
        });
    },
    [edit]
  );

  const onSendNote = useCallback(
    (text) => {
      createNote({ text, orderId: data.id, memberId: auth.user.id });
    },
    [data, auth.user]
  );

  const onUpdateLog = useCallback(() => {
    updateLog(data)
      .then((res) => {
        if (res?.id) {
          getData();
        }
        NotificationManager.success("Логи обновлены");
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, [data]);

  const Catalog = () => {
    const isProduct = useCallback(
      (item) => !!data.orderProducts.find((e) => e.id === item.id),
      [data.orderProducts]
    );

    const onClickCategory = useCallback((item) => setCategories(item.id), []);

    const onClickProduct = useCallback(
      (item) => {
        item = {
          ...item,
          orderId: data.id,
        };
        setValue(
          "orderProducts",
          isProduct(item)
            ? data.orderProducts.filter((e) => e.id !== item.id)
            : [...data.orderProducts, item]
        );
      },
      [data.orderProducts, data.id]
    );

    const Product = (item) => {
      return (
        <Col md={3}>
          <a
            onClick={() => onClickProduct(item)}
            className={
              "d-flex flex-column justify-content-between product-mini" +
              (isProduct(item) ? " active" : "")
            }
          >
            <div>
              <p className="fs-08 title">{item.title}</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <span className="fw-5">{customPrice(item.price)}</span>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Popover body className="fs-09">
                    <strong className="fw-6">Состав</strong>
                    <p className="text-muted mb-2">{item.description}</p>
                    <strong className="fw-6">
                      Энергетическая ценность в 100г
                    </strong>
                    <p className="text-muted">Белки 19.0 г</p>
                    <p className="text-muted">Жиры 13.0 г</p>
                    <p className="text-muted">Углеводы 11.0 г</p>
                  </Popover>
                }
              >
                <span>
                  <IoAlertCircleOutline size={20} className="text-muted icon" />
                </span>
              </OverlayTrigger>
            </div>
          </a>
        </Col>
      );
    };

    const Category = (item) => {
      return (
        <Col md={3}>
          <a
            onClick={() => onClickCategory(item)}
            className={"btn btn-category-light w-100"}
          >
            {item.title}
          </a>
        </Col>
      );
    };
    return (
      <>
        {/* <Row className="gx-2">
          {catalog?.length > 0 && catalog.map((item) => <Category {...item} />)}
        </Row>
        <Row className="gx-2">
          {products?.length > 0 &&
            products.map((item) => <Product {...item} />)}
        </Row> */}
      </>
    );
  };

  if (loading) {
    return <Loader full />;
  }

  if (!data) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такого заказа нет</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title={"Заказ №" + data.id} />
      <div>
        <Link
          to="/"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад в заказы
        </Link>
      </div>

      <Row>
        <Col md={4} className="position-relative">
          <div className="position-sticky top-1">
            <Card className="mb-3 p-3">
              <div className="d-flex align-items-center">
                {/* <div className="pe-2">
                    <img src="/images/avatar.png" width={35} height={35} />
                  </div> */}
                <div className="w-100 d-flex justify-content-between">
                  <div>
                    <p className="fw-7 fs-09">{data?.name ?? "Не указано"}</p>
                    <p>
                      <a
                        className="text-muted fs-08"
                        href={data?.phone && "tel:" + data.phone}
                      >
                        {data?.phone ?? data?.email ?? "Номер не указан"}
                      </a>
                    </p>
                  </div>
                  <div className="d-flex flex-column justify-content-between align-items-end">
                    {/* <span className="text-success fw-6 d-flex align-items-center fs-08">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M15.5237 19.0041C15.3637 19.0048 15.206 18.967 15.0637 18.8941L9.96366 16.2241L4.86366 18.8941C4.52579 19.0718 4.11625 19.0416 3.80808 18.8163C3.49992 18.591 3.34689 18.21 3.41366 17.8341L4.41366 12.2041L0.293656 8.20411C0.0317118 7.94271 -0.0644601 7.55802 0.0436555 7.20411C0.1619 6.84153 0.476078 6.57778 0.853656 6.52411L6.55366 5.69411L9.06366 0.56411C9.23074 0.21912 9.58033 0 9.96366 0C10.347 0 10.6966 0.21912 10.8637 0.56411L13.4037 5.68411L19.1037 6.51411C19.4812 6.56778 19.7954 6.83153 19.9137 7.19411C20.0218 7.54802 19.9256 7.93271 19.6637 8.19411L15.5437 12.1941L16.5437 17.8241C16.6165 18.2069 16.4604 18.5972 16.1437 18.8241C15.9626 18.951 15.7445 19.0143 15.5237 19.0041Z"
                            fill="#00AB55"
                          />
                        </svg>
                        &nbsp;{data?.user?.point ? data.user.point : 0}
                        &nbsp;Баллов
                      </span> */}
                    <Link
                      className="btn btn-sm fw-6"
                      to={`/user/${data.userId}`}
                    >
                      В профиль
                    </Link>
                  </div>
                </div>
              </div>
            </Card>
            {/* {statuses.reservation || statuses.new ? (
                edit ? (
                  <Row>
                    <Col md={8}>
                      <Button className="btn-blue w-100 mb-3">
                        Сохранить изменения
                      </Button>
                    </Col>
                    <Col md={4}>
                      <a
                        className="btn w-100 mb-3"
                        onClick={() => setEdit(!edit)}
                      >
                        Отмена
                      </a>
                    </Col>
                  </Row>
                ) : (
                  <a
                    className="btn-primary w-100 mb-3"
                    onClick={() => setEdit(!edit)}
                  >
                    Изменить
                  </a>
                )
              ) : null} */}

            <Card className="mb-2" body>
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="h6 fw-7 mb-0">Товары на сумму</h5>
                <h5 className="h6 fw-7 mb-0">{customPrice(data.total)}</h5>
              </div>
              {data.deliveryPrice > 0 && (
                <p className="mt-3 mb-0 fs-09 d-flex align-items-center justify-content-between">
                  <span className="text-muted me-2">
                    {t("Стоимость доставки")}:
                  </span>
                  {customPrice(data.deliveryPrice)}
                </p>
              )}
              {data.pointAccrual > 0 && (
                <p className="mt-3 mb-0 fs-09 d-flex align-items-center justify-content-between">
                  <span className="text-muted me-2">
                    {t("Начисление баллов")}:
                  </span>
                  +{customPrice(data.pointAccrual, "Б")}
                </p>
              )}
              {data.pointWriting > 0 && (
                <p className="mt-1 mb-0 fs-09 d-flex align-items-center justify-content-between">
                  <span className="text-muted me-2">
                    {t("Списание баллов")}:
                  </span>
                  -{customPrice(data.pointWriting, "Б")}
                </p>
              )}
              {data.pickupDescount > 0 && (
                <p className="mt-1 mb-0 fs-09 d-flex align-items-center justify-content-between">
                  <span className="text-muted me-2">
                    {t("Скидка за самовывоз")}:
                  </span>
                  -{customPrice(data.pickupDescount)}
                </p>
              )}
              {data.discount > 0 && (
                <p className="mt-1 mb-0 fs-09 d-flex align-items-center justify-content-between">
                  <span className="text-muted me-2">{t("Скидка")}:</span>-
                  {customPrice(data.discount)}
                </p>
              )}
              {(data.deliveryPrice > 0 ||
                data.pointWriting > 0 ||
                data.pickupDescount > 0 ||
                data.discount > 0) && (
                <div className="mt-3 d-flex align-items-center justify-content-between">
                  <h5 className="h6 fw-7 mb-0">Итого</h5>
                  <h5 className="h6 fw-7 mb-0">
                    {customPrice(
                      data.total +
                        (data.deliveryPrice ?? 0) -
                        (data.pointWriting ?? 0) -
                        (data.pickupDescount ?? 0) -
                        (data.discount ?? 0)
                    )}
                  </h5>
                </div>
              )}
            </Card>
            {data?.products?.length > 0 &&
              data.products.map((e) => (
                <Card className="p-3 mb-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      {/* <div className="pe-2">
                          <img
                            src="/images/empty-product-image.png"
                            width={35}
                            height={35}
                          />
                        </div> */}
                      <div>
                        <p className="fs-08">
                          <span className="text-success me-2">{e.count}х</span>
                          {e.title}
                        </p>
                      </div>
                    </div>
                    <div>
                      <p className="fw-6 fs-09">
                        {e.discount > 0 &&
                          customPrice(
                            e?.modifiers?.length > 0
                              ? e.options.modifierPriceSum
                                ? e.modifiers.reduce(
                                    (sum, item) => sum + item.price,
                                    0
                                  ) *
                                    e.count +
                                  e.price * e.count -
                                  e.discount
                                : e.modifiers.reduce(
                                    (sum, item) => sum + item.price,
                                    0
                                  ) *
                                    e.count -
                                  e.discount
                              : e.price * e.count - e.discount,
                            false,
                            auth?.user?.lang
                          )}
                        <div className={e.discount > 0 ? "discount ms-1" : ""}>
                          {customPrice(
                            e?.modifiers?.length > 0
                              ? e.options.modifierPriceSum
                                ? e.modifiers.reduce(
                                    (sum, item) => sum + item.price,
                                    0
                                  ) +
                                  e.price * e.count
                                : e.modifiers.reduce(
                                    (sum, item) => sum + item.price,
                                    0
                                  ) * e.count
                              : e.price * e.count,
                            false,
                            auth?.user?.lang
                          )}
                        </div>
                      </p>
                    </div>
                  </div>
                  {e?.modifiers?.length > 0 &&
                    e.modifiers.map((e) => (
                      <p className="fs-08 mt-1 fw-6 d-flex justify-content-between">
                        <span>{e.title}</span>
                      </p>
                    ))}
                  {e?.wishes?.length > 0 &&
                    e.wishes.map((e) => (
                      <p className="fs-08 mt-1 fw-6 d-flex justify-content-between">
                        <span>{e.title}</span>
                      </p>
                    ))}
                  {e?.additions?.length > 0 &&
                    e.additions.map((e) => (
                      <p className="fs-08 mt-1 fw-6 d-flex justify-content-between">
                        <span>{e.title}</span>
                        {e.price > 0 && <span>{customPrice(e.price)}</span>}
                      </p>
                    ))}
                </Card>
              ))}
          </div>
        </Col>
        <Col md={8}>
          <Card>
            <Card.Body>
              <div className="mb-3 d-flex align-items-center justify-content-between">
                <h3>Заказ №{data.id}</h3>
                {moment(data.createdAt).format("DD MMM YYYY kk:mm:ss")}
                <Status disabled={edit} data={data} />
              </div>
              <div className="position-relative">
                <div className="badge-status-bg" />
                <Row className="flex-row-reverse status-progress">
                  <Col className={statuses.done ? "active" : ""}>
                    <div className="d-flex flex-column align-items-end">
                      <div className="badge-status justify-content-end">
                        <div
                          className={
                            statuses.canceled?.active && "status-order-canceled"
                          }
                        />
                      </div>
                      <p className="fs-09 mt-3 status-title">
                        {statuses?.canceled?.active ? "Отменен" : "Завершен"}
                      </p>
                      {statuses?.canceled?.active && statuses.canceled.start ? (
                        <p className="fs-07">
                          {moment(statuses.canceled.start).format(
                            "DD.MM.YYYY kk:mm"
                          )}
                        </p>
                      ) : (
                        statuses.done.start && (
                          <p className="fs-07">
                            {moment(statuses.done.start).format(
                              "DD.MM.YYYY kk:mm"
                            )}
                          </p>
                        )
                      )}
                    </div>
                  </Col>
                  {data.delivery == "delivery" && (
                    <Col className={statuses.delivery ? "active" : ""}>
                      <div className="d-flex flex-column align-items-center">
                        <div className="badge-status">
                          <div />
                        </div>
                        <p className="fs-09 mt-3 status-title">Доставка</p>
                        {statuses?.delivery?.end && (
                          <p className="fs-07">
                            {moment(statuses.delivery.end).format(
                              "DD.MM.YYYY kk:mm"
                            )}
                          </p>
                        )}
                      </div>
                    </Col>
                  )}
                  <Col className={statuses.prepared ? "active" : ""}>
                    <div className="d-flex flex-column align-items-center">
                      <div className="badge-status">
                        <div />
                      </div>
                      <p className="fs-09 mt-3 status-title">На выдаче</p>
                      {statuses?.prepared?.end && (
                        <p className="fs-07">
                          {moment(statuses.prepared.end).format(
                            "DD.MM.YYYY kk:mm"
                          )}
                        </p>
                      )}
                    </div>
                  </Col>

                  <Col className={statuses.preparing ? "active" : ""}>
                    <div className="d-flex flex-column align-items-center">
                      <div className="badge-status">
                        <div />
                      </div>
                      <p className="fs-09 mt-3 status-title">Готовится</p>
                      {statuses?.preparing?.end && (
                        <p className="fs-07">
                          {moment(statuses.preparing.end).format(
                            "DD.MM.YYYY kk:mm"
                          )}
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col
                    className={
                      statuses.reservation || statuses.new ? "active" : ""
                    }
                  >
                    <div className="d-flex flex-column align-items-start">
                      <div className="badge-status justify-content-start">
                        <div
                          className={
                            statuses.reservation &&
                            data.serving &&
                            "status-order-progress-reservation"
                          }
                        />
                      </div>
                      <p className="fs-09 mt-3 status-title">
                        {statuses.reservation && data.serving
                          ? "Предзаказ"
                          : "Новый"}
                      </p>
                      <p className="fs-07">
                        {statuses.reservation && data.serving
                          ? moment(data.serving).format("DD.MM.YYYY kk:mm")
                          : moment(data.createdAt).format("DD.MM.YYYY kk:mm")}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card.Body>
            <Tabs defaultActiveKey="2" className="mb-3" fill>
              {/* <Tab eventKey="1" title="Каталог" className="px-4 pb-4">
                  {edit ? (
                    <Catalog />
                  ) : (
                    <div className="w-100 py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center">
                      Редактирование товаров отключено
                    </div>
                  )}
                </Tab> */}
              <Tab eventKey="2" title="Основное" className="px-4 pb-4">
                <p className="mb-3">
                  Источник: <b>{data.type === "app" ? "Приложение" : "Сайт"}</b>
                </p>
                {data?.user?.sessions[0]?.id && data.user.sessions[0].brand && (
                  <p className="mb-3">
                    Устройство:{" "}
                    <b>
                      {data.user.sessions[0].brand}{" "}
                      {data.user.sessions[0].modelName} (
                      {data.user.sessions[0].osName}{" "}
                      {data.user.sessions[0].osVersion})
                    </b>
                  </p>
                )}
                {data?.user?.sessions[0]?.ip &&
                  data?.user?.sessions[0]?.ip != "0.0.0.0" && (
                    <p className="mb-3">
                      IP: <b>{data.user.sessions[0].ip}</b>
                    </p>
                  )}
                {data?.user?.sessions[0]?.version && (
                  <p className="mb-3">
                    Версия: <b>{data.user.sessions[0].version}</b>
                  </p>
                )}
                <Controller
                  name="delivery"
                  control={control}
                  defaultValue={data.delivery}
                  render={({ field }) => {
                    return (
                      <ButtonToolbar className="mb-4">
                        <ToggleButtonGroup
                          {...field}
                          onChange={(e) => edit && setValue(field.name, e)}
                        >
                          <ToggleButton
                            id="delivery"
                            disabled={!edit}
                            value="delivery"
                          >
                            Доставка
                          </ToggleButton>
                          <ToggleButton
                            id="pickup"
                            disabled={!edit}
                            value="pickup"
                          >
                            Самовывоз
                          </ToggleButton>
                          <ToggleButton id="hall" disabled={!edit} value="hall">
                            В зале
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </ButtonToolbar>
                    );
                  }}
                />

                {data.delivery == "delivery" ? (
                  <>
                    <Row className="mb-4">
                      <Col md="6">
                        <Input
                          defaultValue={data.country}
                          label="Страна"
                          name="country"
                          placeholder="Нет страны"
                          errors={errors}
                          readOnly={edit}
                          register={register}
                        />
                      </Col>
                      <Col md="6">
                        <Input
                          defaultValue={data.city}
                          label="Город"
                          name="city"
                          placeholder="Нет города"
                          errors={errors}
                          readOnly={edit}
                          register={register}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col md="8">
                        <Input
                          defaultValue={data.street}
                          label="Улица"
                          name="street"
                          placeholder="Нет улицы"
                          errors={errors}
                          readOnly={edit}
                          register={register}
                          validation={{
                            required: "Обязательное поле",
                          }}
                        />
                      </Col>
                      <Col md="2">
                        <Input
                          defaultValue={data.home}
                          label="Дом"
                          name="home"
                          errors={errors}
                          readOnly={edit}
                          register={register}
                          validation={{
                            required: "Обязательное поле",
                          }}
                        />
                      </Col>
                      <Col md="2">
                        <Input
                          defaultValue={data.apartment}
                          label="Квартира"
                          name="apartment"
                          errors={errors}
                          readOnly={edit}
                          register={register}
                          validation={{
                            required: "Обязательное поле",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col>
                        <Input
                          defaultValue={data.block}
                          label="Корпус"
                          name="block"
                          readOnly={edit}
                          errors={errors}
                          register={register}
                        />
                      </Col>
                      <Col>
                        <Input
                          defaultValue={data.entrance}
                          label="Подъезд"
                          name="entrance"
                          errors={errors}
                          readOnly={edit}
                          register={register}
                          validation={{
                            required: "Обязательное поле",
                          }}
                        />
                      </Col>
                      <Col>
                        <Input
                          defaultValue={data.floor}
                          label="Этаж"
                          name="floor"
                          errors={errors}
                          readOnly={edit}
                          register={register}
                          validation={{
                            required: "Обязательное поле",
                          }}
                        />
                      </Col>
                      <Col>
                        <Input
                          defaultValue={data.code}
                          label="Код"
                          name="code"
                          readOnly={edit}
                          errors={errors}
                          register={register}
                        />
                      </Col>
                    </Row>
                  </>
                ) : data.delivery == "pickup" ? (
                  <Select
                    className="mb-3"
                    value={data?.affiliateId}
                    label="Филиал"
                    data={
                      affiliates?.length > 0 &&
                      affiliates.map((e) => ({
                        title: e.title ? e.title : e.full,
                        subTitle: e.title != e.full && e.full ? e.full : null,
                        value: e.id,
                      }))
                    }
                  />
                ) : (
                  <Row className="mb-3">
                    <Col>
                      <Select
                        value={data?.affiliateId}
                        label="Филиал"
                        data={
                          affiliates?.length > 0 &&
                          affiliates.map((e) => ({
                            title: e.title ? e.title : e.full,
                            subTitle:
                              e.title != e.full && e.full ? e.full : null,
                            value: e.id,
                          }))
                        }
                      />
                    </Col>
                    <Col>
                      <Input
                        defaultValue={data.table}
                        label="Стол"
                        name="table"
                        errors={errors}
                        readOnly={edit}
                        register={register}
                      />
                    </Col>
                  </Row>
                )}
                <p className="mb-4 fs-09 fw-6 text-muted">Дополнительное</p>
                <Row className="mb-3">
                  <Col md={6}>
                    {statuses.reservation ? (
                      <div className="position-relative">
                        <Input
                          type="datetime-local"
                          readOnly={edit}
                          label="Дата и время предзаказа"
                          name="serving"
                          errors={errors}
                          register={register}
                          validation={{
                            required: "Обязательное поле",
                          }}
                        />
                        <a className="d-flex align-items-center text-muted px-2 position-absolute end-0 top-0 bottom-0">
                          <IoTimeOutline size={18} />
                        </a>
                      </div>
                    ) : (
                      <Input
                        defaultValue="Сейчас"
                        readOnly={false}
                        label="Дата и время предзаказа"
                        placeholder="Сейчас"
                      />
                    )}
                  </Col>
                  <Col md={6}>
                    <Input
                      label="Кол-во персон"
                      defaultValue={data.person ?? 1}
                      readOnly={edit}
                      name="person"
                      errors={errors}
                      register={register}
                    />
                  </Col>
                </Row>
                {data.comment && (
                  <div className="comment mb-3">
                    <p className="text-muted fs-08">Комментарий</p>
                    {data.comment}
                  </div>
                )}
                <h5 className="fw-7 mb-3">Примечания</h5>

                <Chat
                  data={notes}
                  emptyText="Нет примечаний"
                  onSubmit={(e) => onSendNote(e)}
                />
              </Tab>
              <Tab eventKey="3" title="Оплата" className="px-4 pb-4">
                <Controller
                  name="payment"
                  control={control}
                  defaultValue={data.payment}
                  render={({ field }) => {
                    return (
                      <ButtonToolbar className="mb-3">
                        <ToggleButtonGroup
                          {...field}
                          onChange={(e) => edit && setValue(field.name, e)}
                        >
                          <ToggleButton
                            id="online"
                            disabled={!edit}
                            value="online"
                          >
                            Онлайн
                          </ToggleButton>
                          <ToggleButton id="card" disabled={!edit} value="card">
                            Банковской картой
                          </ToggleButton>
                          <ToggleButton id="cash" disabled={!edit} value="cash">
                            Наличными
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </ButtonToolbar>
                    );
                  }}
                />
                {}
                {data?.transaction?.length > 0 && (
                  <DataTable
                    columns={transactionColumns}
                    // onChange={(items) => setSelected(items)}
                    data={data.transaction}
                    // header={header}
                    // selectable
                    lite
                    // pagination={orders.pagination}
                  />
                )}
              </Tab>
              <Tab eventKey="4" title="Логи" className="px-4 pb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="d-flex align-items-center mb-2">
                    <span className="me-2 fw-6 fs-09">Статус</span>{" "}
                    <Badge
                      bg={
                        log?.status && log?.status < 250 ? "success" : "danger"
                      }
                    >
                      {log?.status ?? "Нет"}
                    </Badge>
                  </p>

                  <Button
                    className="btn-primary-outline btn-sm"
                    onClick={() => onUpdateLog()}
                  >
                    <IoRefreshOutline size={18} />
                  </Button>
                </div>
                {!log?.data && !log?.config?.data && (
                  <p className="mb-2 mt-3">{data.log}</p>
                )}
                {log?.config?.data && (
                  <p className="mb-2">
                    <div className="mb-1 fw-6 fs-09">Отправлено</div>
                    <div className="fs-08 text-muted">
                      {String(JSON.stringify(log.config.data))}
                    </div>
                  </p>
                )}
                {log?.data && (
                  <p className="mb-2">
                    <div className="mb-1 fw-6 fs-09">Ответ</div>
                    <div className="fs-08 text-muted">
                      {String(JSON.stringify(log.data))}
                    </div>
                  </p>
                )}
                {log?.info && (
                  <p>
                    <div className="mb-1 fw-6 fs-09">Проверка</div>
                    <div className="fs-08 text-muted">
                      {String(JSON.stringify(log.info))}
                    </div>
                  </p>
                )}
              </Tab>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OrderEdit;
