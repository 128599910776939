import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const MenuContent = () => {
  const { t } = useTranslation();
  const brand = useSelector((state) => state.brand.active);

  return (
    <div className="w-100 account-menu-sub-bg">
      <nav className="account-menu-sub">
        <ul>
          <li>
            <NavLink to="slides" end>
              {t("Баннеры")}
            </NavLink>
          </li>
          <li>
            <NavLink to="stories">{t("Истории")}</NavLink>
          </li>
          <li>
            <NavLink to="blogs">{t("Новости")}</NavLink>
          </li>
          {(brand?.type === "shop" || brand?.type == "service") && (
            <li>
              <NavLink to="portfolio">{t("Портфолио")}</NavLink>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default MenuContent;
