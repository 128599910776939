import axios from "axios";
import { ClientJS } from "clientjs";

const createCallback = async (data) => {
  const client = new ClientJS();

  const referrer = document.referrer;
  const language = client.getLanguage();

  const DEVICE = {
    brand: client.getBrowser() ?? "",
    osName: client.getOS() ?? "",
    osVersion: client.getOSVersion() ?? "",
    language: language ?? "ru_RU",
  };

  const response = await axios.postForm(
    "https://api.telegram.org/bot6443598489:AAFfFEzRRV3yYIoGwEfmVVkh7ZpNmA41lqU/sendMessage",
    {
      chat_id: "-1001846556129",
      text: `Имя: ${data.name}\nНомер телефона: ${data.phone}\nИнформация: ${
        DEVICE.brand
      } ${DEVICE.osName} ${DEVICE.osVersion} ${DEVICE.language}\nИсточник: ${
        data?.source && data?.source?.length > 0 ? data.source : referrer ?? "Нету"
      }`,
    }
  );
  return response?.data;
};

export { createCallback };
