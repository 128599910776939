import React, { useState, useRef, useEffect } from "react";
import { Editor, ContentState, EditorState, convertFromHTML } from "draft-js";
import "draft-js/dist/Draft.css";
import { convertToHTML } from "draft-convert";

const CustomEditor = ({ content = "", onChange, autoFocus = false }) => {
  const blocksFromHTML = convertFromHTML(content);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(state)
  );

  const editor = useRef(null);

  const focusEditor = () => editor.current.focus();

  useEffect(() => {
    autoFocus && focusEditor();
  }, []);

  const onChangeText = (editorState) => {
    const html = convertToHTML(editorState.getCurrentContent());
    onChange && onChange(html);
    setEditorState(editorState);
  };

  return (
    <div onClick={focusEditor} className="editor">
      <Editor
        ref={editor}
        editorState={editorState}
        onChange={onChangeText}
        placeholder="Начните вводить текст..."
      />
    </div>
  );
};

export default CustomEditor;
