import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/UI/Button";
import { languageCode, localeData } from "../../../helpers/all";
import { editBrand } from "../../../services/brand";
import { updateBrand } from "../../../store/reducers/brandSlice";
import { getLanguages, updateLanguage } from "../../../services/language";

const LanguageForm = ({ data }) => {
  const brand = useSelector((state) => state?.brand?.active);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [statistic, setStatistic] = useState({
    categories: 0,
    categoriesAll: 0,
    products: 0,
    productsAll: 0,
  });

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      ...brand,
      options: brand.options ?? { lang: ["ru"] },
    },
  });

  const form = useWatch({ control });

  useEffect(() => {
    getLanguages().then((res) => {
      setStatistic(res);
    });
  }, []);

  const onSubmit = useCallback((data) => {
    editBrand(data)
      .then(() => {
        dispatch(updateBrand(data));
        NotificationManager.success("Изменения успешно обновлены");
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  const onLanguage = useCallback((data) => {
    updateLanguage(data)
      .then(() => {
        NotificationManager.success("Перевод запущен...");
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  const LanguageItem = (item) => {
    let active =
      form?.options?.lang?.length > 0
        ? form.options.lang.find((e) => e === item.lang)
        : false;

    const onClick = (e) => {
      setValue(
        "options.lang",
        active
          ? form?.options?.lang.filter((l) => l != e)
          : form?.options?.lang?.length > 0
          ? [...form.options.lang, e]
          : [e]
      );
    };

    return (
      <div className="d-flex col-6 align-items-center justify-content-between flex-row fs-09 pb-2">
        <div className="d-flex align-items-center flex-row">
          <img src={item.image} height={20} className="me-2" />
          {t(item.title)}
        </div>
        {active ? (
          <a
            onClick={() => onClick(item.lang)}
            className="btn btn-sm btn-light"
          >
            Отключить
          </a>
        ) : (
          <a
            onClick={() => onClick(item.lang)}
            className="btn btn-sm btn-primary"
          >
            Подключить
          </a>
        )}
      </div>
    );
  };

  const ProductItem = (item) => {
    let active =
      form?.options?.lang?.length > 0
        ? form.options.lang.find((e) => e === item.lang)
        : false;

    const onClick = (e) => {
      onLanguage({ lang: languageCode(e), type: "product" });
    };

    return (
      active && (
        <div className="d-flex align-items-center justify-content-between flex-row fs-09 pb-2">
          <div className="d-flex align-items-center flex-row">
            <img src={item.image} height={25} className="me-3" />
            <div className="d-flex flex-column">
              <div>
                Переведено <b>{statistic.products[active]}</b> из{" "}
                <b>{statistic.productsAll}</b>
              </div>
              {/* <div className="fs-08 text-muted">
                Последний перевод 10.12.2023 10:10
              </div> */}
            </div>
          </div>
          <a
            onClick={() => onClick(item.lang)}
            className="btn btn-sm btn-primary"
          >
            Перевести
          </a>
        </div>
      )
    );
  };

  const CategoryItem = (item) => {
    let active =
      form?.options?.lang?.length > 0
        ? form.options.lang.find((e) => e === item.lang)
        : false;

    const onClick = (e) => {
      onLanguage({ lang: languageCode(e), type: "category" });
    };

    return (
      active && (
        <div className="d-flex align-items-center justify-content-between flex-row fs-09 pb-2">
          <div className="d-flex align-items-center flex-row">
            <img src={item.image} height={25} className="me-3" />
            <div className="d-flex flex-column">
              <div>
                Переведено <b>{statistic.categories[active]}</b> из{" "}
                <b>{statistic.categoriesAll}</b>
              </div>
              {/* <div className="fs-08 text-muted">
                Последний перевод 10.12.2023 10:10
              </div> */}
            </div>
          </div>
          <a
            onClick={() => onClick(item.lang)}
            className="btn btn-sm btn-primary"
          >
            Перевести
          </a>
        </div>
      )
    );
  };

  return (
    <Card body className="mb-4">
      <h3 className="mb-3 fw-7">Настройки</h3>
      <div className="mb-4">
        {localeData
          .filter((e) => e.lang !== "ru")
          .map((e) => (
            <LanguageItem {...e} />
          ))}
      </div>

      <Row>
        <Col md={6}>
          <h3 className="mb-3 fw-7">Товары</h3>
          <div className="mb-4">
            {localeData
              .filter((e) => e.lang !== "ru")
              .map((e) => (
                <ProductItem {...e} />
              ))}
          </div>
        </Col>
        <Col md={6}>
          <h3 className="mb-3 fw-7">Категории</h3>
          <div className="mb-4">
            {localeData
              .filter((e) => e.lang !== "ru")
              .map((e) => (
                <CategoryItem {...e} />
              ))}
          </div>
        </Col>
      </Row>
      <div className="d-flex justify-content-end">
        <Button onClick={handleSubmit(onSubmit)}>Сохранить изменения</Button>
      </div>
    </Card>
  );
};
export default LanguageForm;
