import axios from "axios";
import { BASE_URL } from "../config/api";
import store from "../store";
import { refreshAuth } from "./auth";
import { ClientJS } from "clientjs";
import { languageCode } from "../helpers/all";

const $api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

const client = new ClientJS();
const browser = client.getBrowserData();
const language = client.getLanguage();

const DEVICE = JSON.stringify({
  brand: browser.browser.name ?? "",
  osName: browser.os.name ?? "",
  osVersion: browser.os.version ?? "",
  language: languageCode(language),
});

$api.interceptors.request.use(
  async (config) => {
    // config.headers["Content-Type"] = "application/json";
    config.headers.device = DEVICE;
    return config;
  },
  (error) => Promise.reject(error)
);

const $authApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

$authApi.interceptors.request.use(
  async (config) => {
    const ip = store.getState().settings.ip;
    const brandId = store.getState().brand.active?.id;
    config.headers.ip = ip ?? "0.0.0.0";
    config.headers.brandid = brandId;
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers.device = DEVICE;
    return config;
  },
  (error) => Promise.reject(error)
);

$authApi.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const {
      config,
      response: { status },
    } = error
    const originalRequest = config

    if (status === 401 && originalRequest && !originalRequest._isRetry) {
      originalRequest._isRetry = true
      return store.dispatch(refreshAuth()).then(() => $authApi(originalRequest))
    }
    return Promise.reject(error)
  }
);

export { $api, $authApi };
