import { ru } from "date-fns/locale/ru";
import React, { forwardRef, useState } from "react";
import { Button } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { IoClose } from "react-icons/io5";
registerLocale("ru", ru);

function DatePeriod({
  onDateRangeSelected,
  start = null,
  end = null,
  onDeleteStart,
  onDeleteEnd,
}) {
  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);

  const CustomDate = forwardRef(
    ({ value, placeholder, onClick, className }, ref) => (
      <button className={className} onClick={onClick} ref={ref}>
        {value ? value : placeholder}
      </button>
    )
  );

  const handleDateRangeChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleConfirm = () => {
    onDateRangeSelected(startDate, endDate);
  };

  return (
    <>
      <div className="d-flex flex-row align-items-center">
        <DatePicker
          locale="ru"
          monthsShown={2}
          className="input-date-period ms-2"
          placeholderText="Показать за период"
          withPortal
          id="startDate"
          selectsRange
          maxDate={new Date()}
          showDisabledMonthNavigation
          selected={startDate}
          onChange={(date) => handleDateRangeChange(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          isClearable
          dateFormat="dd.MM.yyyy"
          customInput={<CustomDate className="bg-light" />}
        />
      </div>
      <Button
        variant="primary"
        className="ms-2 btn-small"
        onClick={handleConfirm}
      >
        Применить
      </Button>
    </>
  );
}

export default DatePeriod;
