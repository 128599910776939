import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";

const CustomModal = ({
  className = "",
  classNameBody = "",
  classNameFooter = "",
  setShow,
  show = false,
  size,
  classNameHeader,
  title,
  children,
  footer,
  full = false,
  backdrop,
  closeButton = true,
}) => {
  const closeModal = () => setShow(false);
  const { t } = useTranslation();
  return (
    <Modal
      className={className}
      show={show}
      onHide={closeModal}
      centered
      size={size}
      backdrop={backdrop}
      fullscreen={full}
    >
      <Modal.Header closeButton={closeButton} className={classNameHeader}>
        {title ? <h5>{t(title)}</h5> : null}
      </Modal.Header>
      <Modal.Body className={classNameBody}>{children}</Modal.Body>
      {footer && (
        <Modal.Footer className={classNameFooter}>{footer}</Modal.Footer>
      )}
    </Modal>
  );
};

export default CustomModal;
