import React, { useCallback, useLayoutEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import PreviewImages from "../../components/PreviewImages";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Textarea from "../../components/UI/Textarea";
import { editSale, getSale } from "../../services/sale";

const SaleEdit = () => {
  const navigate = useNavigate();
  const { saleId } = useParams();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const affiliate = useSelector((state) => state.affiliate.data);

  const {
    control,
    register,
    formState: { errors, isValid },
    reset,
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  useLayoutEffect(() => {
    getSale(saleId)
      .then((res) => {
        reset(res);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  const onSubmit = useCallback((data) => {
    var formData = new FormData();

    formData.append("dataParse", JSON.stringify(data));

    if (data?.file?.length > 0) {
      for (let file of data.file) {
        formData.append("file", file);
      }
    } else if (data?.file) {
      formData.append("file", data.file);
    }

    editSale(formData)
      .then(() => {
        NotificationManager.success("Акция успешо обновлена");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          typeof error?.response?.data?.error == "string"
            ? error.response.data.error
            : "Неизвестная ошибка"
        );
      });
  }, []);

  if (loading) {
    return <Loader full />;
  }

  if (!data) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такой акции нет</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title="Редактирование акции" />
      <div>
        <Link
          to="/sales/all"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>Редактирование акции</h3>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить изменения
        </Button>
      </div>
      <Card>
        <Card.Body>
          <Tabs className="mb-3" fill>
            <Tab.Pane eventKey={0} title={t("Основное")}>
              <Row>
                <Col md={4}>
                  <DragDropFile
                    maxAspect={16 / 7}
                    file={data.file}
                    onChange={(e) => setValue("file", e)}
                    onDelete={(e) => setValue("file", e.file)}
                  />
                  {data?.medias && (
                    <PreviewImages
                      medias={data?.medias}
                      type="sale"
                      col={12}
                      main
                      onMain={(e) => setValue("main", e)}
                      onDelete={(e) => {
                        setValue("medias", e.medias);
                        let newArrayDelete =
                          data?.delete?.length > 0
                            ? [...data.delete, e.delete]
                            : [e.delete];
                        setValue("delete", newArrayDelete);
                      }}
                    />
                  )}
                </Col>

                <Col md={8}>
                  <div className="mb-3">
                    <Input
                      defaultValue={data.title}
                      label="Название"
                      name="title"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Обязательное поле",
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <Input
                      defaultValue={data.priority ?? 0}
                      label="Порядок"
                      name="priority"
                      errors={errors}
                      register={register}
                    />
                  </div>
                  <div className="mb-3">
                    <Textarea
                      rows={6}
                      defaultValue={data.desc}
                      label="Описание"
                      name="desc"
                      errors={errors}
                      register={register}
                    />
                  </div>
                  <h5 className="mb-4">Настройки даты и времени показа</h5>
                  <Row>
                    <Col md={6}>
                      <Input
                        label="Начало"
                        type="datetime-local"
                        name="options.start"
                        register={register}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        label="Конец"
                        type="datetime-local"
                        name="options.end"
                        register={register}
                      />
                    </Col>
                  </Row>
                  <Form.Check className="mt-3">
                    <Form.Check.Input
                      type="checkbox"
                      name="status"
                      id="status"
                      defaultChecked={data.status}
                      {...register("status")}
                    />
                    <Form.Check.Label htmlFor="status" className="ms-2">
                      Показать\Скрыть акцию
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey={1} title={t("Филиалы")}>
              <p className="mb-3 text-muted fs-09">
                Если ни один филиал не выбран, баннер будет показан во всех
                филиалах.
              </p>
              {affiliate?.length > 0 && (
                <>
                  <Form.Check className="mb-3">
                    <Form.Check.Input
                      type="checkbox"
                      name="options.affiliateView"
                      checked={data?.options?.affiliateView}
                      id="affiliate_view"
                      onChange={() => {
                        setValue(
                          "options.affiliateView",
                          !!!data?.options?.affiliateView
                        );
                        if (data?.options?.affiliateView) {
                          setValue("relationSales", []);
                        }
                      }}
                    />
                    <Form.Check.Label
                      htmlFor="affiliate_view"
                      className="ms-2 fs-09"
                    >
                      Вкл\Выкл показ по филиалам
                    </Form.Check.Label>
                  </Form.Check>
                  <Form.Check className="mb-3">
                    <Form.Check.Input
                      type="checkbox"
                      disabled={!data?.options?.affiliateView}
                      checked={
                        affiliate?.length > 0 &&
                        affiliate?.length === data?.relationSales?.length
                      }
                      id="affiliate_all"
                      onChange={() => {
                        if (
                          affiliate?.length > 0 &&
                          affiliate?.length === data?.relationSales?.length
                        ) {
                          setValue("relationSales", []);
                        } else {
                          setValue(
                            "relationSales",
                            affiliate.map((e) => ({
                              affiliateId: e.id,
                            }))
                          );
                        }
                      }}
                    />
                    <Form.Check.Label
                      htmlFor="affiliate_all"
                      className="ms-2 fs-09"
                    >
                      Выделить все
                    </Form.Check.Label>
                  </Form.Check>
                  <hr />
                </>
              )}
              {affiliate?.length > 0 &&
                affiliate.map((e) => {
                  let option =
                    data?.relationSales?.length > 0 &&
                    data.relationSales.find(
                      (item) => item.affiliateId === e.id
                    );

                  return (
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Form.Check>
                        <Form.Check.Input
                          type="checkbox"
                          name="relationSales"
                          id={"affiliate_" + e.id}
                          disabled={!data?.options?.affiliateView}
                          checked={!!option}
                          onChange={() => {
                            if (!!option) {
                              let newArray = data.relationSales.filter(
                                (item) => item.affiliateId != e.id
                              );
                              setValue("relationSales", newArray);
                            } else {
                              let newArray =
                                data?.relationSales?.length > 0
                                  ? [
                                      ...data.relationSales,
                                      {
                                        affiliateId: e.id,
                                      },
                                    ]
                                  : [
                                      {
                                        affiliateId: e.id,
                                      },
                                    ];
                              setValue("relationSales", newArray);
                            }
                          }}
                        />
                        <Form.Check.Label
                          htmlFor={"affiliate_" + e.id}
                          className="ms-2 fs-09"
                        >
                          {e?.title ? e.title : e.full}
                        </Form.Check.Label>
                      </Form.Check>
                    </div>
                  );
                })}
            </Tab.Pane>
          </Tabs>
        </Card.Body>
      </Card>
    </>
  );
};

export default SaleEdit;
